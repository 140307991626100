import React from "react";
import { Typography } from "@material-ui/core";

import { connect } from "react-redux";
const Breadcrumb = props => {
    return (<Typography variant={"h6"}>
        {(props.breadcurmb) ? props.breadcurmb : props.breadcrumb.label}
    </Typography>)
}
const mapStateToProps = state => ({
    breadcurmb: state.breadCrumb.name
});
export default connect(mapStateToProps, null)(Breadcrumb);