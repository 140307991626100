import React, { useState, useEffect } from "react";
import { Grid, FormHelperText } from "@material-ui/core";
import useStyles from "../forms/elements/styles";
import Input from "@material-ui/core/TextField";
import { useDispatch, connect } from "react-redux";
// components
import Widget from "../../components/Widget/Widget";
import { Button, Typography } from "../../components/Wrappers/Wrappers";
import { isAuthenticated } from "../../common/isAuthenticated";
import { updateUserProfiles, clearMsg, updateUserProfile } from "../../Redux/_actions/user.action";
import { toast } from "react-toastify";
import { ValidationHandler } from "../../ValidationHandler/validationHandler";
import store from '../../Redux/_store/index'
import imageloader from '../../images/imageloader.gif'
export function Profile(props) {
  const classes = useStyles();
  const user = isAuthenticated();
  var [state, setState] = useState({
    companyId: user && user.company_id ? user.company_id : "",
    companyName: user && user.company_name ? user.company_name : "",
    formErrors: { password: "", confirmPassword: "" },
  });
  const [selectedFile, setSelectedFile] = useState(null)
  const [percentage, setPercentage] = useState(0)
  const [fileFlag, setFileFlag] = useState(false)
  const [companyFlag, setCompanyFlag] = useState(false)
  const [errorFlag, setErrorFlag] = useState(false)
  const [flag, setFlag] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [logoNotSet, setLogoNotSet] = useState('')
  const dispatch = useDispatch();
  toast.configure({
    autoClose: 5000,
    draggable: true
  });

  function setValue(e) {
    if (!companyFlag) {
      setCompanyFlag(true)
    }
    setState({
      ...state,
      [e.target.id]: e.target.value,
    });
  }

  function fileValidation(event) {
    if(event.target.files[0]){
      if (!fileFlag) {
        setFileFlag(true)
      }
      
      var fileInput = event.target.files[0]
      var filePath = fileInput.type;
      var allowedExtensions = /(\image.jpg|\image.jpeg|\image.png)$/i;
      if (allowedExtensions.exec(filePath)) {
        if (errorFlag) { setErrorFlag(false) }
        setSelectedFile(event.target.files[0])
      } else {
        toast.error('selected file invalid, please select any of .jpg , .jpeg and .png format file', {
          position: toast.POSITION.TOP_RIGHT
        });
        if (fileFlag) { setFileFlag(false) }
        if (!errorFlag) { setErrorFlag(true) }
  
      }
    }

  }
  useEffect(() => {
    if (loading) {
      setLoading(false)
    }
  }, [imageUrl])

  const Open = () => {
    window.open(`${imageUrl}`, "_blank");
  }
  useEffect(() => {
    let formData = new FormData();
    formData.append("companies[name]", state.companyName);
    const companyId = state.companyId
    dispatch(updateUserProfiles(true, companyId, formData))
  }, [flag])

  const getImageUrl = () => {
    const state = store.getState();
    const url = state.userData.logoImageUrl
    const ThumbnailUrl = state.userData.logoThumbnailImageUrl
    const logo = state.userData.logoNotSet
    setThumbnailImageUrl(ThumbnailUrl)
    setImageUrl(url)
    setLogoNotSet(logo)
  }
  function updateCompanyName() {
    const companyId = state.companyId
    if (companyId && !errorFlag) {
      if (fileFlag) {
        setLoading(true)
      }
      setState({ ...state });
      let formData = new FormData();
      if (companyFlag) {
        formData.append("companies[name]", state.companyName);
      }
      if (fileFlag && selectedFile !== null) {
        formData.append("companies[logo]", selectedFile);
      }
      if (companyFlag) {
        let userDetails = localStorage.getItem('userData')
        const d = userDetails.replace(`${user.company_name}`, `${state.companyName}`)
        localStorage.setItem('userData', d)
      }
      if (fileFlag || companyFlag) {
        return formData == {} ? "" : dispatch(updateUserProfiles(true, companyId, formData));
      }
      getImageUrl()
      setFileFlag(false)
      setCompanyFlag(false)
      formData = {}
    } else {
      console.log("error")
      setFileFlag(false)
      setCompanyFlag(false)
      if (errorFlag) {
        toast.error('selected file invalid, please select any of .jpg , .jpeg and .png format file', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }
  useEffect(() => {
    const { userData } = props;
    if (
      userData.status === "SUCCESS" &&
      (userData.userData !== "" || userData.signUp !== "") &&
      userData.successMessage
    ) {
      localStorage.setItem("userData", JSON.stringify(userData.userData));
      setState({ ...state });
      toast.success(userData.successMessage, {
        position: toast.POSITION.TOP_RIGHT
      });
      dispatch(clearMsg());
    } else if (userData.status === "FAILED" && userData.errorMessage !== "") {
      toast.error(userData.errorMessage);
      if(loading){setLoading(false)} 
    }
    getImageUrl()
  });
  return (
    <Grid item md={12}>
      <Widget
        title="Update"
        bodyClass={classes.horizontalFormTop}
        disableWidgetMenu
        inheritHeight
      >
        <Grid container direction={"column"} spacing={3} className={"mb-4"}>
          <Grid item container alignItems={"center"}>
            <Grid item xs={4}>
              <Typography variant={"body1"}>Company Name</Typography>
            </Grid>
            <Grid xs={8} item>
              <Input
                id="companyName"
                style={{ width: "100%" }}
                value={state.companyName}
                type={"text"}
                onChange={e => setValue(e)}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction={"column"} spacing={3} className={"mb-4"}>
          <Grid item container alignItems={"center"}>
            <Grid item xs={4}>
              <Typography variant={"body1"}>Company Logo</Typography>
            </Grid>
            <Grid xs={8} item>
              <Input
                id="companyLogo"
                style={{ width: "100%" }}
                type={"file"}
                onChange={fileValidation}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction={"column"} spacing={3} className={"mb-4"}>
          <Grid item container alignItems={"center"}>
            <Grid item xs={4}>
            </Grid>
            <Grid xs={8} item>
              <center>
                {
                  logoNotSet == '' || null ? ('') : (
                    loading ? (<img src={imageloader}></img>) : (
                      imageUrl == '' ? (<img src={imageloader}></img>) :
                        (
                          <img onClick={Open} src={thumbnailImageUrl} alt='something wrong.' ></img>
                        )
                    )
                  )
                }
              </center>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item>
            <Button
              variant={"contained"}
              color={"primary"}
              style={{ marginRight: 8 }}
              onClick={updateCompanyName}
              disabled={!state.companyName}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Widget>
    </Grid>
  );
}
const mapStateToProps = state => ({
  userData: state.userData
});

export default connect(mapStateToProps, null)(Profile);
