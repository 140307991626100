import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  buttonP: {
    padding: 20,
    borderRadius: "15px"
  },
  cardMedia: {
    width: "auto",
    margin: "auto"
  },
  media: {
    width: "60%",
    objectFit: "contain",
    padding: "10px",
    maxHeight: "90px"
  },
  gridC: {
    borderRadius: "15px"
  },
  Img: {
    width: "100%"
  },
  switchBtn: {
    display: "flex"
  },
  cardContent: {
    paddingBottom: "16px"
  },
  setupButton: {
    display: "flex",
    float: "right",
    padding: "10px 0 10px 0"
  },
  logoWidth: {
    width: 240,
    padding: 20
  },
  rowMargin: {
    marginBottom: 20
  },
  textRight: {
    textAlign: "right"
  }
}));
