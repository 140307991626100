export default function loadExternalScript(scriptData) {
    return new Promise((resolve, reject) => {
        const scriptLoaded = document.getElementById(scriptData.id);
        // console.log('scriptLoaded', scriptLoaded);
        if (scriptLoaded) {
            resolve(true);
        } else {
            const script = document.createElement('script');
            script.async = true;
            script.type = "text/javascript";
            script.src = scriptData.src;
            script.id = scriptData.id;
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.head.appendChild(script);
            
        }
    });
};