import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Divider,
  TextField
} from "@material-ui/core";
import { connect, useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Typography, Button } from "../../components/Wrappers";
import useStyles from "./styles";
import { isAuthenticated } from "../../common/isAuthenticated";
import {
  deleteSlackIntegration,
  configSlackIntegration,
  getslackIntegration,
  clearMsg
} from "../../Redux/_actions/user.action";

export function IntegrationSetup(props) {
  const user = isAuthenticated();
  const [authenticatedIntigration, setAuthenticatedIntegration] = useState(
    useSelector(state => state.userData.selectedIntegration)
  );
  const [selectedIntegration, setSelectedIntegration] = useState({
    name: "",
    image_url: "",
    description: "",
    channel: ""
  });
  const company_id = user.company_id;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const url = `https://slack.com/oauth/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=incoming-webhook,chat:write:bot&user_scope=identify&state=${company_id}`;

  const slackImageButton = (
    <a href={`${url}`}>
      <img
        alt="Add to Slack"
        height="40"
        width="139"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </a>
  );

  toast.configure({
    autoClose: 4000,
    draggable: true
  });

  function sweetAlert(text, buttonText) {
    return Swal.fire({
      title: "Are you sure?",
      text,
      icon: "warning",
      animation: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: buttonText
    });
  }

  function showDeletePopUp(integrationId, props) {
    if (integrationId) {
      const text = "Are you sure you want to delete this integration?";
      const buttonText = "Yes, delete it!";
      sweetAlert(text, buttonText).then(result => {
        if (result.value) {
          dispatch(deleteSlackIntegration(integrationId));
        }
      });
    }
  }

  function saveIntegration(integration, slackIndex) {
    if (integration) {
      const formData = new FormData();
      formData.append("[integrations][status]", integration.status === "active" ? true : false);
      formData.append(
        "[integrations][cancellation_started]",
        integration.cancellation_started
      );
      formData.append(
        "[integrations][cancellation_abandoned]",
        integration.cancellation_abandoned
      );
      formData.append(
        "[integrations][cancellation_offer_proposed]",
        integration.cancellation_offer_proposed
      );
      formData.append(
        "[integrations][cancellation_offer_accepted]",
        integration.cancellation_offer_accepted
      );
      formData.append(
        "[integrations][cancellation_completed]",
        integration.cancellation_completed
      );
      dispatch(configSlackIntegration(integration.id, formData, integration));
    }
  }

  function setAuthenticatedSelectedIntegrationHandler(event) {
    const value =
      event.id === "status"
        ? event.checked
          ? "active"
          : "deactive"
        : event.checked;
    setAuthenticatedIntegration({
      ...authenticatedIntigration,
      [event.id]: value
    });
    console.log("authenticatedIntegration", authenticatedIntigration);
  }

  useEffect(() => {
    if (
      props.userData &&
      props.userData.status &&
      props.userData.status === "SLACK DELETED"
    ) {
      props.history.push("/integrations");
      toast.success("Integration Deleted Successfully...!");
      dispatch(clearMsg());
    }
  }, [props.userData.status]);

  useEffect(() => {
    if (
      props.userData &&
      props.userData.status === "SUCCESS" &&
      props.userData.selectedIntegration &&
      props.userData.selectedIntegration.channel_id
    ) {
      setAuthenticatedIntegration(props.userData.selectedIntegration);
      setSelectedIntegration({});
      dispatch(clearMsg());
    }
    if (
      props.userData &&
      props.userData.status === "UPDATED" &&
      props.userData.selectedIntegration
    ) {
      toast.success("Integration Updated Successfully...!");
      dispatch(clearMsg());
    }
  }, [props.userData.selectedIntegration]);

  useEffect(() => {
    const slugName = props.match.params.name;
    const setup =
      props.history.location.state && props.history.location.state.setup;
    if (setup) {
      const integration = props.history.location.state.integration;
      setSelectedIntegration(integration);
      setAuthenticatedIntegration({});
    } else {
      dispatch(getslackIntegration(slugName));
    }
  }, [props.match.params.name]);

  return (
    <Fragment>
      <Grid item md={12} sm={11}>
        {selectedIntegration &&
          !Object.values(authenticatedIntigration).length && (
            <Card className={classes.cardMedia}>
              <CardMedia className={classes.media}>
                <img
                  src={selectedIntegration.image_url}
                  className={classes.media}
                />
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {selectedIntegration.name}
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className={classes.cardContent}
                >
                  {selectedIntegration.description}
                </Typography>
                {/* <Typography>
                  Channel slack is posting to: {selectedIntegration.channel}
                </Typography> */}
                <Divider className={classes.divider}></Divider>

                <br></br>

                <Grid container spacing={4}>
                  {/* <Grid item xs={6}>
                <Typography>Status:</Typography>
                {integration && integration.status === "active" ? (
                  <Chip
                    className={classes.badge}
                    color="primary"
                    label={"Enabled"}
                    />
                    ) : (
                      <Chip
                      className={classes.badge}
                    color="secondary"
                    label={"Disabled"}
                  />
                )}
              </Grid> */}
                  <Grid item xs={12} className={classes.switchBtn}>
                    {slackImageButton}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        {authenticatedIntigration &&
          !Object.values(selectedIntegration).length && (
            <Card className={classes.cardMedia}>
              <CardMedia>
                <img
                  src={authenticatedIntigration.image_url}
                  className={(classes.media, classes.logoWidth)}
                />
              </CardMedia>
              <CardContent>
                {/* <Typography gutterBottom variant="h5" component="h2">
                {integration.name}
              </Typography> */}

                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className={classes.cardContent}
                  item="true"
                  lg={4}
                  sm={12}
                >
                  {authenticatedIntigration.description}
                </Typography>
                {/* <Divider className={classes.divider}></Divider> */}

                <Grid
                  container
                  direction="row"
                  // justify="center"
                  alignItems="center"
                  className={classes.rowMargin}
                >
                  <Grid item md={3} sm={12}>
                    <Typography>Enabled</Typography>
                  </Grid>
                  <Grid item md={9}>
                    <Typography>
                      <Checkbox
                        id={"status"}
                        checked={
                          authenticatedIntigration.status === "active"
                            ? true
                            : false
                        }
                        onChange={event =>
                          setAuthenticatedSelectedIntegrationHandler(
                            event.target
                          )
                        }
                        // inputProps={{ "aria-labelledby": labelId }}
                      />
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  // justify="center"
                  alignItems="center"
                  className={classes.rowMargin}
                >
                  <Grid item md={3} sm={12}>
                    <Typography>Channel</Typography>
                  </Grid>
                  <Grid item md={9}>
                    {/* <Input
                    id="channelName"
                    value={"123"}
                    // onChange={e => setVariableName(e.target.value)}
                    placeholder="#Chhurn notify"
                    variant="outlined"
                  /> */}
                    <TextField
                      id="outlined-basic"
                      value={authenticatedIntigration.channel}
                      disabled={true}
                      // label="#Chhurn notify"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  // justify="center"
                  alignItems="center"
                >
                  <Grid item md={3} sm={12}>
                    <Typography>Post to Slack When:</Typography>
                  </Grid>
                  <Grid item md={9}>
                    <Typography>
                      <Checkbox
                        id={"cancellation_started"}
                        checked={authenticatedIntigration.cancellation_started}
                        onChange={event =>
                          setAuthenticatedSelectedIntegrationHandler(
                            event.target
                          )
                        }
                        // inputProps={{ "aria-labelledby": obj.title }}
                      />
                      Cancellation Started
                    </Typography>
                    <Typography>
                      <Checkbox
                        id={"cancellation_abandoned"}
                        checked={
                          authenticatedIntigration.cancellation_abandoned
                        }
                        onChange={event =>
                          setAuthenticatedSelectedIntegrationHandler(
                            event.target
                          )
                        }
                        // inputProps={{ "aria-labelledby": obj.title }}
                      />
                      Cancellation Abandoned
                    </Typography>
                    <Typography>
                      <Checkbox
                        id={"cancellation_offer_proposed"}
                        checked={
                          authenticatedIntigration.cancellation_offer_proposed
                        }
                        onChange={event =>
                          setAuthenticatedSelectedIntegrationHandler(
                            event.target
                          )
                        }
                        // inputProps={{ "aria-labelledby": obj.title }}
                      />
                      Cancellation Abandoned
                    </Typography>
                    <Typography>
                      <Checkbox
                        id={"cancellation_offer_accepted"}
                        checked={
                          authenticatedIntigration.cancellation_offer_accepted
                        }
                        onChange={event =>
                          setAuthenticatedSelectedIntegrationHandler(
                            event.target
                          )
                        }
                        // inputProps={{ "aria-labelledby": obj.title }}
                      />
                      Cancellation Abandoned
                    </Typography>
                    <Typography>
                      <Checkbox
                        id={"cancellation_completed"}
                        checked={
                          authenticatedIntigration.cancellation_completed
                        }
                        onChange={event =>
                          setAuthenticatedSelectedIntegrationHandler(
                            event.target
                          )
                        }
                        // inputProps={{ "aria-labelledby": obj.title }}
                      />
                      Cancellation Complete
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center">
                  <Grid item md={6}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => {
                        saveIntegration(authenticatedIntigration);
                      }}
                    >
                      Update
                    </Button>
                  </Grid>

                  <Grid className={classes.textRight} item md={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        showDeletePopUp(authenticatedIntigration.id);
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
      </Grid>

      {/* <Grid container spacing={4} className={classes.gridC}>
        {slackIntegrationsSelector.length &&
          slackIntegrationsSelector.map((integration, index) => {
            return (
              <Grid item md={4} sm={11} key={index}>
                <Card className={classes.cardMedia}>
                  <CardMedia className={classes.media}>
                    <img
                      src={integration.image_url}
                      className={classes.media}
                    />
                  </CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {integration.name}
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className={classes.cardContent}
                    >
                      {integration.description}
                    </Typography>
                    {isConfigure && selectedConfigureIndex === index && (
                      <Typography>
                        Channel slack is posting to: {integration.channel}
                      </Typography>
                    )}
                    <Divider className={classes.divider}></Divider>
                   
                    <br></br>

                    { !isConfigure ?  (
                      integration.integration_id && integration.is_auth ? (
                        <Grid container spacing={4}>
                          {integration.status === "active" ? (
                            <Grid item xs={6}>
                              <Typography>Status:</Typography>
                              <Chip
                                className={classes.badge}
                                color="primary"
                                label={"Enabled"}
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={6}>
                              <Typography>Status:</Typography>
                              <Chip
                                className={classes.badge}
                                color="secondary"
                                label={"Disabled"}
                              />
                            </Grid>
                          )}
                          <Grid item xs={6} className={classes.switchBtn}>
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{ marginLeft: "auto" }}
                              className={classes.setupButton}
                              onClick={() =>
                                configuration(integration.integration_id, index)
                              }
                            >
                              Configure
                            </Button>
                          </Grid>
                        </Grid>
                      ) : (
                         slackImageButton
                      )
                    ) : selectedConfigureIndex === index ? (
                      <Grid container spacing={4} className={classes.gridC}>
                        <Grid item xs={6}>
                          <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            className={classes.uninstall}
                            onClick={() => {
                              showDeletePopUp(integration.integration_id);
                            }}
                          >
                            Uninstall
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Switch
                            id="enabled"
                            color="primary"
                            checked={integration.status === "active"}
                            inputProps={{
                              "aria-label": "primary checkbox"
                            }}
                            onClick={() => {
                              saveIntegration(
                                integration.integration_id,
                                integration.status
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                        slackImageButton
                    )}
                  </CardContent>
                </Card>
              </Grid>
                 );
                })}
              </Grid> */}
    </Fragment>
  );
}
const mapStateToProps = props => ({
  userData: props.userData
});
export default connect(mapStateToProps, null)(IntegrationSetup);
