import React, { useState, useEffect } from "react";
import { Grid, FormHelperText } from "@material-ui/core";
import useStyles from "../forms/elements/styles";
import Input from "@material-ui/core/TextField";
import { useDispatch, connect } from "react-redux";
// components
import Widget from "../../components/Widget/Widget";
import { Button, Typography } from "../../components/Wrappers/Wrappers";
import { isAuthenticated } from "../../common/isAuthenticated";
import { updateUserProfile, clearMsg } from "../../Redux/_actions/user.action";
import { toast } from "react-toastify";
import { ValidationHandler } from "../../ValidationHandler/validationHandler";

export function Profile(props) {
  const classes = useStyles();
  const user = isAuthenticated();
  var [state, setState] = useState({
    firstName: user && user.first_name ? user.first_name : "",
    lastName: user && user.last_name ? user.last_name : "",
    email: user && user.email ? user.email : "",
    password: "",
    confirmPassword: "",
    formErrors: { email: "", password: "", confirmPassword: "" },
    emailValid: true,
    passwordValid: true,
    confirmPasswordValid: true
  });

  const dispatch = useDispatch();
  toast.configure({
    autoClose: 5000,
    draggable: true
  });

  function setValue(e) {
    setState({
      ...state,
      [e.target.id]: e.target.value,
      passwordValid: true,
      confirmPasswordValid: true
    });
    // const response = ValidationHandler(e,state);
    // setState({
    //   ...state,
    //   formErrors: response.formErrors,
    //   emailValid: response.emailValid,
    //   passwordValid: response.passwordValid,
    //   [e.target.id]: response.name
    // });
  }

  function updateProfile() {
    const password = state.password;
    const confirmPassword = state.confirmPassword;
    if (password && password.length < 6) {
      setState({ ...state, passwordValid: false });
    } else if (
      state.passwordValid &&
      confirmPassword &&
      confirmPassword !== password
    ) {
      setState({ ...state, confirmPasswordValid: false });
    } else {
      if ((confirmPassword && !password) || (!confirmPassword && password)) {
        toast.error("Please fill detail of password.")
      } else {
        const formData = new FormData();
        formData.append("user[first_name]", state.firstName);
        formData.append("user[last_name]", state.lastName);
        formData.append("user[email]", state.email);
        if (password && confirmPassword) {
          formData.append("user[password]", password);
        }
        dispatch(updateUserProfile(true, null, formData));
      }
    }
  }

  useEffect(() => {
    const { userData } = props;
    if (
      userData.status === "SUCCESS" &&
      (userData.userData !== "" || userData.signUp !== "") &&
      userData.successMessage
    ) {
      localStorage.setItem("userData", JSON.stringify(userData.userData));
      setState({ ...state, password: "", confirmPassword: "" });
      toast.success(userData.successMessage, {
        position: toast.POSITION.TOP_RIGHT
      });
      dispatch(clearMsg());
    } else if (userData.status === "FAILED" && userData.errorMessage !== "") {
      toast.error(userData.errorMessage);
    }
  });
  return (
    <Grid item md={8}>
      <Widget
        title="Profile"
        bodyClass={classes.horizontalFormTop}
        disableWidgetMenu
        inheritHeight
      >
        <Grid container direction={"column"} spacing={3} className={"my-4"}>
          <Grid item container alignItems={"center"}>
            <Grid item xs={4}>
              <Typography variant={"body1"}>First Name</Typography>
            </Grid>
            <Grid xs={8} item>
              <Input
                id="firstName"
                type={"text"}
                style={{ width: "100%" }}
                value={state.firstName}
                onChange={e => setValue(e)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction={"column"} spacing={3} className={"mb-4"}>
          <Grid item container alignItems={"center"}>
            <Grid item xs={4}>
              <Typography variant={"body1"}>Last Name</Typography>
            </Grid>
            <Grid xs={8} item>
              <Input
                id="lastName"
                style={{ width: "100%" }}
                value={state.lastName}
                type={"text"}
                onChange={e => setValue(e)}
                required
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction={"column"} spacing={3} className={"mb-4"}>
          <Grid item container alignItems={"center"}>
            <Grid item xs={4}>
              <Typography variant={"body1"}>Email Address</Typography>
            </Grid>
            <Grid xs={8} item>
              <Input
                id="email"
                style={{ width: "100%" }}
                value={state.email}
                type={"text"}
                onChange={e => setValue(e)}
                disabled
              />
              <FormHelperText error={!state.emailValid}>
                {state.formErrors.email}
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction={"column"} spacing={3} className={"mb-4"}>
          <Grid item container alignItems={"center"}>
            <Grid item xs={4}>
              <Typography variant={"body1"}>Password</Typography>
            </Grid>
            <Grid xs={8} item>
              <Input
                id="password"
                style={{ width: "100%" }}
                value={state.password}
                type={"password"}
                error={!state.passwordValid}
                helperText={
                  !state.passwordValid
                    ? "Password is too short (minimum is 6 characters)"
                    : ""
                }
                onChange={e => setValue(e)}
              />
              {state.passwordValid ? null : (
                <span className={"text text-danger"}>
                  {state.formErrors.password}
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction={"column"} spacing={3} className={"mb-4"}>
          <Grid item container alignItems={"center"}>
            <Grid item xs={4}>
              <Typography variant={"body1"}>Confirm Password</Typography>
            </Grid>
            <Grid xs={8} item>
              <Input
                id="confirmPassword"
                style={{ width: "100%" }}
                value={state.confirmPassword}
                type={"password"}
                error={!state.confirmPasswordValid}
                helperText={
                  !state.confirmPasswordValid
                    ? "Confirm password doesn't match"
                    : ""
                }
                onChange={e => setValue(e)}
              />
              {state.confirmPasswordValid ? null : (
                <span className={"text text-danger"}>
                  {state.formErrors.confirmPassword}
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item>
            <Button
              variant={"contained"}
              color={"primary"}
              style={{ marginRight: 8 }}
              onClick={updateProfile}
              disabled={!state.firstName || !state.lastName}
            >
              Save
            </Button>
          </Grid>
          {/* <Grid item>
            <Button variant={"contained"} color={"secondary"}>
              Cancel
            </Button>
          </Grid> */}
        </Grid>
      </Widget>
    </Grid>
  );
}

const mapStateToProps = state => ({
  userData: state.userData
});

export default connect(mapStateToProps, null)(Profile);
