import React, { Component } from "react";
import loader from '../images/loader.gif'
class Loader extends Component {
  render() {
    return (
      <div style={{ flex: 1, alignContent: 'center', justifyContent: "center"}} >
        
        {this.props.visible === true ?
          <div>
            <div>
            </div>
            <center>
              <img src={loader} alt="" style={{ alignSelf: 'center' , height:"70px", width:"70px" }}
              ></img>
            </center>
          </div> :
          <div></div>
        }
      </div>
    );
  }
}
export default Loader;