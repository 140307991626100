import {
  REQUESTED_CANCELLATION_BEGIN,
  REQUESTED_CANCELLATION_SUCCESS,
  REQUESTED_CANCELLATION_FAILED,
  COMPLETED_CANCELLATION_BEGIN,
  COMPLETED_CANCELLATION_SUCCESS,
  COMPLETED_CANCELLATION_FAILED,
  SUBMITTED_REASON_BEGIN,
  SUBMITTED_REASON_SUCCESS,
  SUBMITTED_REASON_FAILED,
  CANCELLED_REASON_BEGIN,
  CANCELLED_REASON_FAILED,
  CANCELLED_REASON_SUCCESS,
  ALL_CANCELLATIONS_LIST_BEGIN,
  ALL_CANCELLATIONS_LIST_SUCCESS,
  ALL_CANCELLATIONS_LIST_FAILED,
  SET_SELECTED_PAGINATION,
  SET_SELECTED_ORDER,
} from "../_constants";
import { axiosRequest } from "../_requests";
let url
export const requestedCancellation = (headers, params, body) => async (
  dispatch
  ) => {
  dispatch({ type: REQUESTED_CANCELLATION_BEGIN });
  if (headers) {
    try {
      Object.values(body).length <= 0 ?
        (url = "requested_cancellation?date_type=" + params) :
       ( url = "requested_cancellation?date_type=" + params + "&flows_ids=" + body)
      const response = await axiosRequest(
        "GET",
        url,
        true,
        undefined,
        body,
        undefined,
        dispatch
      );
      if (response.status === 200) {
        dispatch({
          type: REQUESTED_CANCELLATION_SUCCESS,
          data: {
            data: response.data,
            messages: response.messages,
          },
        });
      } else {
        dispatch({
          type: REQUESTED_CANCELLATION_FAILED,
          data: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: REQUESTED_CANCELLATION_FAILED,
        data: error.messages,
      });
    }
  }
};

export const completedCancellation = (headers, params, body) => async (
  dispatch
) => {
  dispatch({ type: COMPLETED_CANCELLATION_BEGIN });
  if (headers) {
    try {
      Object.values(body).length <= 0 ?
        (url = "completed_cancellation?date_type=" + params) :
       ( url = "completed_cancellation?date_type=" + params + "&flows_ids=" + body)
      const response = await axiosRequest(
        "GET",
        url,
        true,
        undefined,
        body,
        undefined,
        dispatch
      );
      if (response.status === 200) {
        dispatch({
          type: COMPLETED_CANCELLATION_SUCCESS,
          data: {
            data: response.data,
            messages: response.messages,
          },
        });
      } else {
        dispatch({
          type: COMPLETED_CANCELLATION_FAILED,
          data: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: REQUESTED_CANCELLATION_FAILED,
        data: error.messages,
      });
    }
  }
};

export const submittedReason = (headers, params, body) => async (dispatch) => {
  dispatch({ type: SUBMITTED_REASON_BEGIN });
  if (headers) {
    try {
      Object.values(body).length <= 0 ?
        (url = "cancel_reason?date_type=" + params) :
       ( url = "cancel_reason?date_type=" + params + "&flows_ids=" + body)
      const response = await axiosRequest(
        "GET",
        url,
        true,
        undefined,
        body,
        undefined,
        dispatch
      );
      if (response.status === 200) {
        dispatch({
          type: SUBMITTED_REASON_SUCCESS,
          data: {
            data: response.data,
            messages: response.messages,
          },
        });
      } else {
        dispatch({
          type: SUBMITTED_REASON_FAILED,
          data: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: SUBMITTED_REASON_FAILED,
        data: error.messages,
      });
    }
  }
};

export const cancelledReason = (headers, params, body) => async (dispatch) => {
  dispatch({ type: CANCELLED_REASON_BEGIN });
  if (headers) {
    try {
      Object.values(body).length <= 0 ?
        (url = "not_cancelled_reason?date_type=" + params) :
       ( url = "not_cancelled_reason?date_type=" + params + "&flows_ids=" + body)
      const response = await axiosRequest(
        "GET",
        url,
        true,
        undefined,
        body,
        undefined,
        dispatch
      );
      if (response.status === 200) {
        dispatch({
          type: CANCELLED_REASON_SUCCESS,
          data: {
            data: response.data,
            messages: response.messages,
          },
        });
      } else {
        dispatch({
          type: CANCELLED_REASON_FAILED,
          data: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: REQUESTED_CANCELLATION_FAILED,
        data: error.messages,
      });
    }
  }
};

export const allCancellationsList = (headers, params, body ,pageNo , order) => async (
  dispatch
) => {
  dispatch({ type: ALL_CANCELLATIONS_LIST_BEGIN });
  if (headers) {
    try {
      Object.values(body).length <= 0 ?
        (url = "list_event_user?date_type=" + params + "&page=" + pageNo + "&order=" + order) :
       ( url = "list_event_user?date_type=" + params + "&flows_ids=" + body + "&page=" + pageNo + "&order=" + order)
      const response = await axiosRequest(
        "GET",
        url,
        true,
        undefined,
        body,
        undefined,
        dispatch
      );
      if (response.status === 200) {
        dispatch({
          type: ALL_CANCELLATIONS_LIST_SUCCESS,
          data: {
            data: response.data,
            messages: response.messages,
          },
        });
      } else {
        dispatch({
          type: ALL_CANCELLATIONS_LIST_FAILED,
          data: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ALL_CANCELLATIONS_LIST_FAILED,
        data: error.messages,
      });
    }
  }
};

export const selectedPagination = (number) => async (
  dispatch
  ) => {
  dispatch({ type: SET_SELECTED_PAGINATION,
             number });
};
export const selectedOrder = (value) => async (
  dispatch
  ) => {
  dispatch({ type: SET_SELECTED_ORDER,
             value });
};
