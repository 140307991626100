import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TablePagination,
  TableHead,
  TableSortLabel,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  Legend,
  AreaChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
  Tooltip,
} from "recharts";

// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget";
import { Chip, Typography, Avatar } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import BigStat from "./components/BigStat/BigStat";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import { lighten } from "@material-ui/core/styles";
import cn from "classnames";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
// Actions
import {
  requestedCancellation,
  completedCancellation,
  submittedReason,
  cancelledReason,
  allCancellationsList,
  selectedPagination,
  selectedOrder,
} from "../../Redux/_actions/dashboard.action";
import {
  fetchFlowList,
} from "../../Redux/_actions/flow.action";
import { SUCCESS, FAILED, PENDING } from "../../Redux/_constants";

const colors = scaleOrdinal(schemeCategory10).range();

const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

const TicketChartData = [
  { name: "Client 1", value: 2, color: "primary" },
  { name: "Client 2", value: 2, color: "primary" },
  { name: "Client 3", value: 2, color: "primary" },
  { name: "Client 4", value: 2, color: "primary" },
  { name: "Client 5", value: 2, color: "primary" },
  { name: "Client 6", value: 2, color: "primary" },
  { name: "Client 7", value: 2, color: "primary" },
  { name: "Client 8", value: 2, color: "primary" },
  { name: "Client 9", value: 2, color: "primary" },
  { name: "Client 10", value: 2, color: "primary" },
  { name: "Client 11", value: 2, color: "primary" },
  { name: "Client 12", value: 2, color: "primary" },
  { name: "Client 13", value: 2, color: "primary" },
  { name: "Client 14", value: 2, color: "primary" },
  { name: "Client 15", value: 2, color: "primary" },
  { name: "Client 16", value: 2, color: "primary" },
  { name: "Client 17", value: 2, color: "primary" },
  { name: "Client 18", value: 2, color: "primary" },
  { name: "Client 19", value: 2, color: "primary" },
  { name: "Client 20", value: 2, color: "primary" },
];

// Recent Orders
const rows = [
  {
    id: 1,
    orderId: Math.floor(Math.random(0) * 3000000),
    customer: "Victoria Cantrel",
    office: "Croatia",
    weight: "1.4 kg",
    price: 23.87,
    purDate: "12 Jan 2019",
    delDate: "-",
    status: "Pending",
    color: "primary",
  },
  {
    id: 2,
    orderId: Math.floor(Math.random(0) * 3000000),
    customer: "Cherokee Ware",
    office: "Belgium",
    weight: "0.8 kg",
    price: 987,
    purDate: "11 Jan 2019",
    delDate: "14 Jan 2019",
    status: "Delivered",
    color: "success",
  },
  {
    id: 3,
    orderId: Math.floor(Math.random(0) * 3000000),
    customer: "Constance Clayton",
    office: "Peru",
    weight: "105 kg",
    price: 1.876,
    purDate: "09 Jan 2019",
    delDate: "-",
    status: "Canceled",
    color: "secondary",
  },
  {
    id: 4,
    orderId: Math.floor(Math.random(0) * 3000000),
    customer: "Cherokee Ware",
    office: "Belgium",
    weight: "0.8 kg",
    price: 987,
    purDate: "11 Jan 2019",
    delDate: "14 Jan 2019",
    status: "Delivered",
    color: "success",
  },
  {
    id: 5,
    orderId: Math.floor(Math.random(0) * 3000000),
    customer: "Constance Clayton",
    office: "Peru",
    weight: "105 kg",
    price: 1.876,
    purDate: "06 Jan 2019",
    delDate: "19 Jan 2019",
    status: "In a process",
    color: "warning",
  },
];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  { id: "name", numeric: true, disablePadding: false, label: "Name" },
  { id: "company", numeric: true, disablePadding: false, label: "Company" },
  { id: "email", numeric: true, disablePadding: false, label: "Email" },
  { id: "mrr", numeric: true, disablePadding: false, label: "MRR" },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
];
// const headCells = [
//   {
//     id: "id",
//     numeric: true,
//     disablePadding: true,
//     label: "Order ID",
//   },
//   { id: "customer", numeric: true, disablePadding: false, label: "Customer" },
//   { id: "office", numeric: true, disablePadding: false, label: "Office" },
//   { id: "weight", numeric: true, disablePadding: false, label: "Netto Weight" },
//   { id: "price", numeric: true, disablePadding: false, label: "Price" },
//   {
//     id: "purchase-date",
//     numeric: true,
//     disablePadding: false,
//     label: "Date of purchase",
//   },
//   {
//     id: "delivery-date",
//     numeric: true,
//     disablePadding: false,
//     label: "Date of Delivery",
//   },
//   { id: "status", numeric: true, disablePadding: false, label: "Status" },
// ];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all rows" }}
            style={{ color: "rgb(185, 185, 185)" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "right"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
              style={{
                whiteSpace: "nowrap",
                textTransform: "uppercase",
                fontSize: "0.85rem",
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, totalRows } = props;

  return (
    <Toolbar
      className={cn(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : (
          <Box display={"flex"} className={classes.title}>
            <Typography
              variant="h6"
              color="text"
              colorBrightness={"secondary"}
              id="tableTitle"
              style={{ display: "flex" }}
              block
            >
              Cancellation List
            <Box display="flex" alignSelf={"flex-end"} ml={1}>
                <Typography
                  color="text"
                  colorBrightness={"hint"}
                  variant={"caption"}
                >
                  {totalRows} total
              </Typography>
              </Box>
            </Typography>
          </Box>
        )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
};

const Dashboard = (props) => {
  var classes = useStyles();
  var theme = useTheme();
  // local
  var [mainChartState, setMainChartState] = useState("monthly");

  // Recent Orders table
  const pageNo = props.flowPagination - 1
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dateType = props.selectedDateType;
  const currentData = generateData(dateType);
  const flows_ids = props.selected_flow_Ids
  const [pageStatus, setPageStatus] = React.useState(false)
  const [count, setCount] = React.useState(0)
  // Requested Cancellation
  useEffect(() => {
    setPage(pageNo)
  }, [pageNo])
  const [
    requestedCancellationLoading,
    setRequestedCancellationLoading,
  ] = React.useState(false);
  const [requestedCancellations, setRequestedCancellations] = React.useState(
    []
  );

  // Complete Cancellation
  const [
    completedCancellationLoading,
    setCompletedCancellationLoading,
  ] = React.useState(false);
  const [completedCancellations, setCompletedCancellations] = React.useState(
    []
  );

  // Submitted Reason
  const [submittedReasonLoading, setSubmittedReasonLoading] = React.useState(
    false
  );
  const [submittedReasons, setSubmittedReasons] = React.useState([]);

  // Cancelled Reason
  const [cancelledReasonLoading, setCancelledReasonLoading] = React.useState(
    false
  );
  const [cancelledReasons, setCancelledReasons] = React.useState([]);

  const [allCancellationsData, setAllCancellationsData] = React.useState([]);
  const composedChartsData = [
    {
      id: 1,
      title: "Requested Cancellation",
      data: {},
    },
    {
      id: 2,
      title: "Completed Cancellation",
      data: {},
    },
  ];

  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };
  useEffect(() => {
    dispatch(allCancellationsList(true, dateType, flows_ids, page + 1, order))
    dispatch(selectedOrder(order))
  }, [order])
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allCancellationsData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (!pageStatus) {
      setPageStatus(true)
    }
    dispatch(selectedPagination(newPage + 1));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, allCancellationsData.length - page * rowsPerPage);

  const composeRequestedCancellation = () => {
    setChartData(
      "lineChart",
      props.requestedCancellations,
      setRequestedCancellations
    );
  };

  const composeCompletedCancellation = () => {
    setChartData(
      "lineChart",
      props.completedCancellations,
      setCompletedCancellations
    );
  };

  const setChartData = (type, requestData, setData) => {
    if (type === "lineChart") {
      let cancellations = [];
      const currentMonth = new Date().getMonth() + 1;
      for (let i = 0; i < currentData.length; i++) {
        let el = {};
        const requestValue =
          dateType === 4
            ? currentMonth < i + 1
              ? null
              : 0
            : new Date(currentData[i]) > new Date()
              ? null
              : 0;
        el = {
          day: currentData[i],
          requests: requestValue,
        };

        if (requestData[el.day]) {
          el["requests"] = requestData[el.day];
        }
        // if (new Date(el.day) > new Date()) {
        //   delete el.requests;
        // }
        //  console.log("el", el);
        cancellations.push(el);
      }
      setData(cancellations);
    } else {
      let data = [];
      for (let key in requestData) {
        let el = {
          reason: key,
          count: requestData[key],
        };
        data.push(el);
      }
      setData(data);
    }
  };

  const composeSubmittedReason = () => {
    setChartData("pieChart", props.submittedReasons, setSubmittedReasons);
  };

  const composeCancelledReason = () => {
    setChartData("pieChart", props.cancelledReasons, setCancelledReasons);
  };

  const composeAllCancellationsList = () => {
    const allCancellationsList = [];
    Object.values(props.allCancellationsList.event_data).map((data, index) => {
      allCancellationsList.push({ ...data, id: index + 1 });
    })
    const count = props.allCancellationsList.total_pages
    setCount(count)
    setAllCancellationsData(allCancellationsList);
  };
  useEffect(() => {
    switch (props.requestedCancellationLoading) {
      case SUCCESS:
        composeRequestedCancellation();
        setRequestedCancellationLoading(false);
        break;
      case FAILED:
        setRequestedCancellationLoading(false);
        break;
      case PENDING:
        setRequestedCancellationLoading(true);
        break;
      default:
        setRequestedCancellationLoading(false);
    }
  }, [props.requestedCancellationLoading]);

  useEffect(() => {
    switch (props.completedCancellationLoading) {
      case SUCCESS:
        composeCompletedCancellation();
        setCompletedCancellationLoading(false);
        break;
      case FAILED:
        setCompletedCancellationLoading(false);
        break;
      case PENDING:
        setCompletedCancellationLoading(true);
        break;
      default:
        setCompletedCancellationLoading(false);
    }
  }, [props.completedCancellationLoading]);

  useEffect(() => {
    switch (props.submittedReasonLoading) {
      case SUCCESS:
        composeSubmittedReason();
        setSubmittedReasonLoading(false);
        break;
      case FAILED:
        setSubmittedReasonLoading(false);
        break;
      case PENDING:
        setSubmittedReasonLoading(true);
        break;
      default:
        setSubmittedReasonLoading(false);
    }
  }, [props.submittedReasonLoading]);

  useEffect(() => {
    switch (props.cancelledReasonLoading) {
      case SUCCESS:
        composeCancelledReason();
        setCancelledReasonLoading(false);
        break;
      case FAILED:
        setCancelledReasonLoading(false);
        break;
      case PENDING:
        setCancelledReasonLoading(true);
        break;
      default:
        setCancelledReasonLoading(false);
    }
  }, [props.cancelledReasonLoading]);

  useEffect(() => {
    if (props.allCancellationsListStatusLoading === SUCCESS) {
      composeAllCancellationsList();
    }
  }, [props.allCancellationsListStatusLoading]);

  useEffect(() => {
    dispatch(requestedCancellation(true, dateType, flows_ids));
    dispatch(completedCancellation(true, dateType, flows_ids));
    dispatch(submittedReason(true, dateType, flows_ids));
    dispatch(cancelledReason(true, dateType, flows_ids));
    dispatch(allCancellationsList(true, dateType, flows_ids, page + 1, order));
  }, [props.selectedDateType]);
  let flow_list
  useEffect(() => {
    dispatch(fetchFlowList(true, null, null));
  }, [flow_list])
  useEffect(() => {
    if (pageStatus) {
      dispatch(allCancellationsList(true, dateType, flows_ids, page + 1, order))
    }
  }, [page])

  composedChartsData[0].data = requestedCancellations;
  composedChartsData[1].data = completedCancellations;
  const selectColor = (reason, index) => {
    let color
    switch (reason.reason) {
      case "Missing Features":
        color = "#1f77b4"
        return <Cell key={`slice-${index}`} fill={color} />
        break;
      case 'Too Expensive':
        color = "#ff7f0e"
        return <Cell key={`slice-${index}`} fill={color} />

        break;
      case "Our Needs Have Changed":
        color = "#d62728"
        return <Cell key={`slice-${index}`} fill={color} />
        break;

      case `Don't Use It Enough`:
        color = "#2ca02c"
        return <Cell key={`slice-${index}`} fill={color} />
        break;

      case "Found a Better Solution":
        color = "#9467bd"
        return <Cell key={`slice-${index}`} fill={color} />
        break;
      case "Other":
        color = "#8c564b"
        return <Cell key={`slice-${index}`} fill={color} />
        break;

      default:
        break;
    }
  }
  return (
    <>
      <Grid container spacing={3}>
        {composedChartsData.length &&
          composedChartsData.map((data) => {
            return (
              <Grid item xs={6} key={data.id}>
                <Widget
                  bodyClass={classes.mainChartBody}
                  header={
                    <div className={classes.mainChartHeader}>
                      <Typography
                        variant="h6"
                        color="text"
                        weight={"medium"}
                        colorBrightness="secondary"
                      >
                        {data.title}
                      </Typography>
                    </div>
                  }
                >
                  <ResponsiveContainer width="100%" minWidth={500} height={350}>
                    <ComposedChart
                      margin={{ top: 0, right: -15, left: -15, bottom: 0 }}
                      data={data.data}
                    >
                      <YAxis
                        tick={{
                          fill: theme.palette.text.hint + "80",
                          fontSize: 14,
                        }}
                        stroke={theme.palette.text.hint + "80"}
                        tickLine={false}
                      />
                      <XAxis
                        tick={{
                          fill: theme.palette.text.hint + "80",
                          fontSize: 14,
                        }}
                        stroke={theme.palette.text.hint + "80"}
                        tickLine={false}
                        dataKey="day"
                      />
                      <Tooltip />
                      <Line
                        type="linear"
                        dataKey="requests"
                        stroke={theme.palette.warning.main}
                        strokeWidth={2}
                        dot={{
                          stroke: theme.palette.warning.dark,
                          strokeWidth: 2,
                          fill: theme.palette.warning.main,
                        }}
                        activeDot={{
                          r: 8,
                        }}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </Widget>
              </Grid>
            );
          })}
        {/* <Grid item xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h6"
                  color="text"
                  weight={"medium"}
                  colorBrightness="secondary"
                >
                  Completed Cancellation
                </Typography>
              </div>
            }
          >
            <ResponsiveContainer width="100%" minWidth={500} height={350}>
              <ComposedChart
                margin={{ top: 0, right: -15, left: -15, bottom: 0 }}
                data={completedCancellations}
              >
                <YAxis
                  tick={{
                    fill: theme.palette.text.hint + "80",
                    fontSize: 14,
                  }}
                  stroke={theme.palette.text.hint + "80"}
                  tickLine={false}
                />
                <XAxis
                  tick={{
                    fill: theme.palette.text.hint + "80",
                    fontSize: 14,
                  }}
                  stroke={theme.palette.text.hint + "80"}
                  tickLine={false}
                  dataKey="day"
                />
                <Tooltip />
                <Line
                  type="linear"
                  dataKey="requests"
                  stroke={theme.palette.warning.main}
                  strokeWidth={2}
                  dot={{
                    stroke: theme.palette.warning.dark,
                    strokeWidth: 2,
                    fill: theme.palette.warning.main,
                  }}
                  activeDot={{
                    r: 8,
                  }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Widget>
        </Grid> */}
        <Grid item sm={6} xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h6"
                  color="text"
                  weight={"medium"}
                  colorBrightness="secondary"
                >
                  Submitted Reasons
                </Typography>
              </div>
            }
          >
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Legend verticalAlign="top" className="mb-4" />
                <Pie
                  data={submittedReasons}
                  nameKey="reason"
                  dataKey="count"
                  innerRadius="35%"
                  outerRadius="80%"
                >
                  {submittedReasons.map((reason, index) => (
                    selectColor(reason, index)
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Widget>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h6"
                  color="text"
                  weight={"medium"}
                  colorBrightness="secondary"
                >
                  Cancelled Reasons
                </Typography>
              </div>
            }
          >
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Legend verticalAlign="top" className="mb-4" />
                <Pie
                  data={cancelledReasons}
                  nameKey="reason"
                  dataKey="count"
                  innerRadius="35%"
                  outerRadius="80%"
                >
                  {cancelledReasons.map((reason, index) => (
                    selectColor(reason, index)
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <Widget upperTitle noBodyPadding bodyClass={classes.tableWidget}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              totalRows={allCancellationsData.length}
            />
            <div className={classes.tableWrapper}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="cancellation status"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={allCancellationsData.length}
                />
                <TableBody>
                  {stableSort(allCancellationsData,getSorting(order, orderBy))
                  .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `orders-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                              style={{ color: "rgb(185, 185, 185)" }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.id}
                          </TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.company}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>{row.mmr}</TableCell>
                          <TableCell>{row.date}</TableCell>
                          <TableCell>
                            <Chip label={row.status} color={"success"} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && !allCancellationsData.length && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} className={"text text-center"}>
                        No Data Available.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              labelRowsPerPage={'per_page:'}
              rowsPerPageOptions={[10,20]}
              component="div"
              count={count * 10}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  console.log(resultArray);
  return resultArray;
}

function generateData(dateType) {
  let today = new Date();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();
  let currentDate = today.getDate();
  let days = [];
  switch (dateType) {
    case 1:
      let date = new Date(`${year}-${month}-${currentDate}`);
      days.push(
        `${date.getFullYear()}-${pad(date.getMonth() + 1, 2)}-${pad(
          date.getDate(),
          2
        )}`
      );
      break;
    case 2:
      let week = [];
      for (let i = 1; i <= 7; i++) {
        let first = today.getDate() - today.getDay() + i;
        let day = new Date(today.setDate(first)).toISOString().slice(0, 10);
        week.push(day);
      }
      days = week;
      break;
    case 3:
      for (let i = 1; i < 32; i++) {
        let date = new Date(`${year}-${month}-${i}`);
        days.push(
          `${date.getFullYear()}-${pad(date.getMonth() + 1, 2)}-${pad(
            date.getDate(),
            2
          )}`
        );
      }
      break;
    case 4:
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      days = months;
      break;
  }
  return days;
}

function pad(num, size) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

const mapStateToProps = (state) => ({
  requestedCancellationLoading: state.analytics.requestedCancellationStatus,
  completedCancellationLoading: state.analytics.completedCancellationStatus,
  submittedReasonLoading: state.analytics.submittedReasonStatus,
  cancelledReasonLoading: state.analytics.cancelledReasonStatus,
  allCancellationsListStatusLoading: state.analytics.allCancellationsListStatus,
  requestedCancellations: state.analytics.requestedCancellations,
  completedCancellations: state.analytics.completedCancellations,
  submittedReasons: state.analytics.submittedReasons,
  cancelledReasons: state.analytics.cancelledReasons,
  submittedStatus: state.analytics.submittedStatus,
  allCancellationsList: state.analytics.allCancellationsList,
  flowLists: state.flows.flowList,
  flowPagination: state.analytics.selectedPagination
});

export default connect(mapStateToProps, null)(Dashboard);
