/**
|--------------------------------------------------
| COMMON CONSTANTS
|--------------------------------------------------
*/

export const PENDING = "PENDING";
export const SUCCESS = "SUCCESS";
export const FAILED = "FAILED";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const SESSION_EXPIRED_SUCCESS = "SESSION_EXPIRED_SUCCESS";

/**
|--------------------------------------------------
| SIGN UP CONSTANTS
|--------------------------------------------------
*/

export const SIGN_UP_BEGIN = "SIGN_UP_BEGIN";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILED = "SIGN_UP_FAILED";

/**
|--------------------------------------------------
| INVITED USER REGISTER CONSTANTS
|--------------------------------------------------
*/

export const INVITED_USER_REGISTER_BEGIN = "INVITED_USER_REGISTER_BEGIN";
export const INVITED_USER_REGISTER_SUCCESS = "INVITED_USER_REGISTER_SUCCESS";
export const INVITED_USER_REGISTER_FAILED = "INVITED_USER_REGISTER_FAILED";

/**
|--------------------------------------------------
| DELETE INVITED USER CONSTANTS
|--------------------------------------------------
*/

export const DELETE_USER_INVITATION_BEGIN = "DELETE_USER_INVITATION_BEGIN";
export const DELETE_USER_INVITATION_SUCCESS = "DELETE_USER_INVITATION_SUCCESS";
export const DELETE_USER_INVITATION_FAILED = "DELETE_USER_INVITATION_FAILED";

/**
|--------------------------------------------------
| INVITED USER REGISTER CONSTANTS
|--------------------------------------------------
*/

export const FETCH_INVITED_USER_LIST_BEGIN = "FETCH_INVITED_USER_LIST_BEGIN";
export const FETCH_INVITED_USER_LIST_SUCCESS = "FETCH_INVITED_USER_LIST_SUCCESS";
export const FETCH_INVITED_USER_LIST_FAILED = "FETCH_INVITED_USER_LIST_FAILED";
export const FILTER_INVITED_USER_LIST = "FILTER_INVITED_USER_LIST";

/**
|--------------------------------------------------
| LOGIN CONSTANTS
|--------------------------------------------------
*/

export const SIGN_IN_BEGIN = "SIGN_IN_BEGIN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILED = "SIGN_IN_FAILED";

/**
|--------------------------------------------------
| LOGOUT CONSTANTS
|--------------------------------------------------
*/

export const SIGN_OUT_BEGIN = "SIGN_OUT_BEGIN";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAILED = "SIGN_OUT_FAILED";

/**
|--------------------------------------------------
| LOGIN WITH GOOGLE CONSTANTS
|--------------------------------------------------
*/

export const SIGN_IN_WITH_GOOGLE_BEGIN = "SIGN_IN_WITH_GOOGLE_BEGIN";
export const SIGN_IN_WITH_GOOGLE_SUCCESS = "SIGN_IN_WITH_GOOGLE_SUCCESS";
export const SIGN_IN_WITH_GOOGLE_FAILED = "SIGN_IN_WITH_GOOGLE_FAILED";

/**
|--------------------------------------------------
| UPDATE USER PROFILE
|--------------------------------------------------
*/

export const UPDATE_USER_PROFILE_BEGIN = "UPDATE_USER_PROFILE_BEGIN";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILED = "UPDATE_USER_PROFILE_FAILED";

/**
|--------------------------------------------------
| FETCH VARIABLES LIST
|--------------------------------------------------
*/

export const FETCH_VARIABLE_LIST_BEGIN = "FETCH_VARIABLE_LIST_BEGIN";
export const FETCH_VARIABLE_LIST_SUCCESS = "FETCH_VARIABLE_LIST_SUCCESS";
export const FETCH_VARIABLE_LIST_FAILED = "FETCH_VARIABLE_LIST_FAILED";
export const FILTER_VARIABLES_LIST = "FILTER_VARIABLES_LIST";

/**
|--------------------------------------------------
| ADD VARIABLE
|--------------------------------------------------
*/

export const ADD_VARIABLE_BEGIN = "ADD_VARIABLE_BEGIN";
export const ADD_VARIABLE_SUCCESS = "ADD_VARIABLE_SUCCESS";
export const ADD_VARIABLE_FAILED = "ADD_VARIABLE_FAILED";

/**
|--------------------------------------------------
| UPDATE VARIABLE
|--------------------------------------------------
*/

export const UPDATE_VARIABLE_BEGIN = "UPDATE_VARIABLE_BEGIN";
export const UPDATE_VARIABLE_SUCCESS = "UPDATE_VARIABLE_SUCCESS";
export const UPDATE_VARIABLE_FAILED = "UPDATE_VARIABLE_FAILED";

/**
|--------------------------------------------------
| DELETE VARIABLE
|--------------------------------------------------
*/

export const DELETE_VARIABLE_BEGIN = "DELETE_VARIABLE_BEGIN";
export const DELETE_VARIABLE_SUCCESS = "DELETE_VARIABLE_SUCCESS";
export const DELETE_VARIABLE_FAILED = "DELETE_VARIABLE_FAILED";


/**
|--------------------------------------------------
| FETCH FLOW LIST
|--------------------------------------------------
*/

export const FETCH_RETENTION_FLOW_LIST_BEGIN = "FETCH_RETENTION_FLOW_LIST_BEGIN";
export const FETCH_RETENTION_FLOW_LIST_SUCCESS = "FETCH_RETENTION_FLOW_LIST_SUCCESS";
export const FETCH_RETENTION_FLOW_LIST_FAILED = "FETCH_RETENTION_FLOW_LIST_FAILED";
export const FILTER_RETENTION_FLOW_LIST = "FILTER_RETENTION_FLOW_LIST";
export const CLEAR_FLOW_STATE_MESSAGE = "CLEAR_FLOW_STATE_MESSAGE";

/**
|--------------------------------------------------
| FETCH FLOW
|--------------------------------------------------
*/

export const FETCH_RETENTION_FLOW_BEGIN = "FETCH_RETENTION_FLOW_BEGIN";
export const FETCH_RETENTION_FLOW_SUCCESS = "FETCH_RETENTION_FLOW_SUCCESS";
export const FETCH_RETENTION_FLOW_FAILED = "FETCH_RETENTION_FLOW_FAILED";
export const SET_SELECTED_FLOW = "SET_SELECTED_FLOW";


/**
|--------------------------------------------------
| ADD FLOW
|--------------------------------------------------
*/

export const ADD_RETENTION_FLOW_BEGIN = "ADD_RETENTION_FLOW_BEGIN";
export const ADD_RETENTION_FLOW_SUCCESS = "ADD_RETENTION_FLOW_SUCCESS";
export const ADD_RETENTION_FLOW_FAILED = "ADD_RETENTION_FLOW_FAILED";

/**
|--------------------------------------------------
| UPDATE FLOW
|--------------------------------------------------
*/

export const UPDATE_RETENTION_FLOW_BEGIN = "UPDATE_RETENTION_FLOW_BEGIN";
export const UPDATE_RETENTION_FLOW_SUCCESS = "UPDATE_RETENTION_FLOW_SUCCESS";
export const UPDATE_RETENTION_FLOW_FAILED = "UPDATE_RETENTION_FLOW_FAILED";

/**
|--------------------------------------------------
| UPDATE FLOW STEP
|--------------------------------------------------
*/

export const UPDATE_RETENTION_FLOW_STEP_BEGIN = "UPDATE_RETENTION_FLOW_STEP_BEGIN";
export const UPDATE_RETENTION_FLOW_STEP_SUCCESS = "UPDATE_RETENTION_FLOW_STEP_SUCCESS";
export const UPDATE_RETENTION_FLOW_STEP_FAILED = "UPDATE_RETENTION_FLOW_STEP_FAILED";

/**
|--------------------------------------------------
| DELETE FLOW
|--------------------------------------------------
*/

export const DELETE_RETENTION_FLOW_BEGIN = "DELETE_RETENTION_FLOW_BEGIN";
export const DELETE_RETENTION_FLOW_SUCCESS = "DELETE_RETENTION_FLOW_SUCCESS";
export const DELETE_RETENTION_FLOW_FAILED = "DELETE_RETENTION_FLOW_FAILED";

/**
|--------------------------------------------------
| ADD OFFER
|--------------------------------------------------
*/

export const UPDATE_ADD_OFFER_BEGIN = "UPDATE_ADD_OFFER_BEGIN";
export const UPDATE_ADD_OFFER_SUCCESS = "UPDATE_ADD_OFFER_SUCCESS";
export const UPDATE_ADD_OFFER_FAILED = "UPDATE_ADD_OFFER_FAILED";

/**
|--------------------------------------------------
| ACCEPTED OFFER
|--------------------------------------------------
*/

export const ACCEPTED_OFFER_BEGIN = "ACCEPTED_OFFER_BEGIN";
export const ACCEPTED_OFFER_SUCCESS = "ACCEPTED_OFFER_SUCCESS";
export const ACCEPTED_OFFER_FAILED = "ACCEPTED_OFFER_FAILED";

/**
|--------------------------------------------------
| CLEAR SUCCESS AND ERROR MESSAGE
|--------------------------------------------------
*/

export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SESSION_EXPIRED = "SESSION_EXPIRED";

/**
|--------------------------------------------------
| DASHBOARD REQUESTED CANCELLATION
|--------------------------------------------------
*/

export const REQUESTED_CANCELLATION_BEGIN = "REQUESTED_CANCELLATION_BEGIN";
export const REQUESTED_CANCELLATION_SUCCESS = "REQUESTED_CANCELLATION_SUCCESS";
export const REQUESTED_CANCELLATION_FAILED = "REQUESTED_CANCELLATION_FAILED";

/**
|--------------------------------------------------
| DASHBOARD COMPLETED CANCELLATION
|--------------------------------------------------
*/

export const COMPLETED_CANCELLATION_BEGIN = "COMPLETED_CANCELLATION_BEGIN";
export const COMPLETED_CANCELLATION_SUCCESS = "COMPLETED_CANCELLATION_SUCCESS";
export const COMPLETED_CANCELLATION_FAILED = "COMPLETED_CANCELLATION_FAILED";

/**
|--------------------------------------------------
| DASHBOARD SUBMITTED REASON
|--------------------------------------------------
*/

export const SUBMITTED_REASON_BEGIN = "SUBMITTED_REASON_BEGIN";
export const SUBMITTED_REASON_SUCCESS = "SUBMITTED_REASON_SUCCESS";
export const SUBMITTED_REASON_FAILED = "SUBMITTED_REASON_FAILED";


/**
|--------------------------------------------------
| DASHBOARD CANCELLED REASON
|--------------------------------------------------
*/

export const CANCELLED_REASON_BEGIN = "CANCELLED_REASON_BEGIN";
export const CANCELLED_REASON_SUCCESS = "CANCELLED_REASON_SUCCESS";
export const CANCELLED_REASON_FAILED = "CANCELLED_REASON_FAILED";

/**
|--------------------------------------------------
| DASHBOARD ALL CANCELLATIONS STARTED
|--------------------------------------------------
*/

export const ALL_CANCELLATIONS_LIST_BEGIN = "ALL_CANCELLATIONS_LIST_BEGIN";
export const ALL_CANCELLATIONS_LIST_SUCCESS = "ALL_CANCELLATIONS_LIST_SUCCESS";
export const ALL_CANCELLATIONS_LIST_FAILED = "ALL_CANCELLATIONS_LIST_FAILED";


/**
|--------------------------------------------------
| DASHBOARD SUBMIT STATUS
|--------------------------------------------------
*/

export const SUBMITTED_STATUS_BEGIN = "SUBMITTED_STATUS_BEGIN";
export const SUBMITTED_STATUS_SUCCESS = "SUBMITTED_STATUS_SUCCESS";
export const SUBMITTED_STATUS_FAILED = "SUBMITTED_STATUS_FAILED";

export const GOOGLEAUTH = {
  AUTH_URL: `${process.env.REACT_APP_API_SERVER_URL}/users/auth/google_oauth2/callback`,
  HEADERS: {
    Authorization: "Bearer ******",
    "Content-Type": "application/x-www-form-urlencoded"
  }
};


/**
|--------------------------------------------------
| SLACK CONFIG 
|--------------------------------------------------
*/

export const SLACK_INTEGRATION_BEGIN = "SLACK_INTEGRATION_BEGIN";
export const SLACK_INTEGRATION_SUCCESS = "SLACK_INTEGRATION_SUCCESS";
export const SLACK_INTEGRATION_FAILED = "SLACK_INTEGRATION_FAILED";
export const GET_SLACK_INTEGRATION_BEGIN = "GET_SLACK_INTEGRATION_BEGIN";
export const GET_SLACK_INTEGRATION_SUCCESS = "GET_SLACK_INTEGRATION_SUCCESS";
export const GET_SLACK_INTEGRATION_FAILED = "GET_SLACK_INTEGRATION_FAILED";
export const DELETE_SLACK_INTEGRATION_BEGIN = "DELETE_SLACK_INTEGRATION_BEGIN";
export const DELETE_SLACK_INTEGRATION_SUCCESS = "DELETE_SLACK_INTEGRATION_SUCCESS";
export const DELETE_SLACK_INTEGRATION_FAILED = "DELETE_SLACK_INTEGRATION_FAILED";
export const INTEGRATION_CONFIG_BEGIN = "INTEGRATION_CONFIG_BEGIN";
export const INTEGRATION_CONFIG_SUCCESS = "INTEGRATION_CONFIG_SUCCESS";
export const INTEGRATION_CONFIG_FAILED = "INTEGRATION_CONFIG_FAILED";


/**
|--------------------------------------------------
| SET BREADCRUMB 
|--------------------------------------------------
*/

export const SET_BREADCRUMB_NAME = "SET_BREADCRUMB_NAME";

/**
|--------------------------------------------------
| SET SELECTED PAGINATION 
|--------------------------------------------------
*/
export const SET_SELECTED_PAGINATION = "SET_SELECTED_PAGINATION";

/**
|--------------------------------------------------
| SET SELECTED ORDER
|--------------------------------------------------
*/
export const SET_SELECTED_ORDER = "SET_SELECTED_ORDER";

/**
|--------------------------------------------------
| UPDATE USER PROFILEs
|--------------------------------------------------
*/

export const UPDATE_USER_PROFILES_BEGIN = "UPDATE_USER_PROFILES_BEGIN";
export const UPDATE_USER_PROFILES_SUCCESS = "UPDATE_USER_PROFILES_SUCCESS";
export const UPDATE_USER_PROFILES_FAILED = "UPDATE_USER_PROFILES_FAILED";

/**
|--------------------------------------------------
| SET LOGO_IMAGE_URL
|--------------------------------------------------
*/
export const SET_LOGO_IMAGE_URL = "SET_LOGO_IMAGE_URL";
