import {
  SUCCESS,
  FAILED,
  PENDING,
  REQUESTED_CANCELLATION_BEGIN,
  REQUESTED_CANCELLATION_SUCCESS,
  REQUESTED_CANCELLATION_FAILED,
  COMPLETED_CANCELLATION_BEGIN,
  COMPLETED_CANCELLATION_SUCCESS,
  COMPLETED_CANCELLATION_FAILED,
  SUBMITTED_REASON_BEGIN,
  SUBMITTED_REASON_SUCCESS,
  SUBMITTED_REASON_FAILED,
  CANCELLED_REASON_BEGIN,
  CANCELLED_REASON_FAILED,
  CANCELLED_REASON_SUCCESS,
  ALL_CANCELLATIONS_LIST_BEGIN,
  ALL_CANCELLATIONS_LIST_SUCCESS,
  ALL_CANCELLATIONS_LIST_FAILED,
  SET_SELECTED_PAGINATION,
  SET_SELECTED_ORDER,
} from "../_constants";

const initialState = {
  requestedCancellationStatus: "",
  completedCancellationStatus: "",
  submittedReasonStatus: "",
  cancelledReasonStatus: "",
  allCancellationsListStatus: "",
  requestedCancellations: {},
  completedCancellations: {},
  submittedReasons: {},
  cancelledReasons: {},
  allCancellationsList: {},
  selectedPagination: 1,
  defaultOrder: 'desc'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUESTED_CANCELLATION_BEGIN:
      return {
        ...state,
        requestedCancellationStatus: PENDING,
        errorMessage: "",
        successMessage: "",
      };

    case REQUESTED_CANCELLATION_SUCCESS:
      return {
        ...state,
        requestedCancellationStatus: SUCCESS,
        requestedCancellations: action.data.data,
        errorMessage: "",
        successMessage: "",
      };

    case REQUESTED_CANCELLATION_FAILED:
      return {
        ...state,
        requestedCancellationStatus: FAILED,
        errorMessage: action.data,
        successMessage: "",
      };

    case COMPLETED_CANCELLATION_BEGIN:
      return {
        ...state,
        completedCancellationStatus: PENDING,
        errorMessage: "",
        successMessage: "",
      };

    case COMPLETED_CANCELLATION_SUCCESS:
      return {
        ...state,
        completedCancellationStatus: SUCCESS,
        completedCancellations: action.data.data,
        errorMessage: "",
        successMessage: "",
      };

    case COMPLETED_CANCELLATION_FAILED:
      return {
        ...state,
        completedCancellationStatus: FAILED,
        errorMessage: action.data,
        successMessage: "",
      };

    case SUBMITTED_REASON_BEGIN:
      return {
        ...state,
        submittedReasonStatus: PENDING,
        errorMessage: "",
        successMessage: "",
      };

    case SUBMITTED_REASON_SUCCESS:
      return {
        ...state,
        submittedReasonStatus: SUCCESS,
        submittedReasons: action.data.data,
        errorMessage: "",
        successMessage: "",
      };

    case SUBMITTED_REASON_FAILED:
      return {
        ...state,
        submittedReasonStatus: FAILED,
        errorMessage: action.data,
        successMessage: "",
      };

    case CANCELLED_REASON_BEGIN:
      return {
        ...state,
        cancelledReasonStatus: PENDING,
        errorMessage: "",
        successMessage: "",
      };

    case CANCELLED_REASON_SUCCESS:
      return {
        ...state,
        cancelledReasonStatus: SUCCESS,
        cancelledReasons: action.data.data,
        errorMessage: "",
        successMessage: "",
      };

    case CANCELLED_REASON_FAILED:
      return {
        ...state,
        cancelledReasonStatus: FAILED,
        errorMessage: action.data,
        successMessage: "",
      };

    case ALL_CANCELLATIONS_LIST_BEGIN:
      return {
        ...state,
        allCancellationsListStatus: PENDING,
        errorMessage: "",
        successMessage: "",
      };

    case ALL_CANCELLATIONS_LIST_SUCCESS:
      return {
        ...state,
        allCancellationsListStatus: SUCCESS,
        allCancellationsList: action.data.data,
        errorMessage: "",
        successMessage: "",
      };

    case ALL_CANCELLATIONS_LIST_FAILED:
      return {
        ...state,
        allCancellationsListStatus: FAILED,
        errorMessage: action.data,
        successMessage: "",
      };
    case SET_SELECTED_PAGINATION:
      return {
        ...state,
        selectedPagination: action.number,
      };
    case SET_SELECTED_ORDER:
      return {
        ...state,
        defaultOrder: action.value,
      };


    default:
      return state;
  }
};
