import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden"
  },
  content: {
    flexGrow: 1,
    padding: 38,
    width: `calc(100vw - 240px)`,
    minHeight: "100vh"
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  fakeToolbar: {
    ...theme.mixins.toolbar
  },
  link: {
    marginRight: theme.spacing(2)
  },
  noMargin: {
    margin: 0
  },
  defaultRadio: {
    color: "#536DFE",
    "&.MuiRadio-colorSecondary.Mui-checked": {
      color: "#536DFE"
    }
  },
  successRadio: {
    color: "#23a075",
    "&.MuiRadio-colorSecondary.Mui-checked": {
      color: "#23a075"
    }
  },
  secondaryRadio: {
    color: "#FF5C93",
    "&.MuiRadio-colorSecondary.Mui-checked": {
      color: "#FF5C93"
    }
  },
  PreviewButton:{


    justifyContent:"flex-end",
    backgroundColor:'#3cd4a1',
    color:'white',
   '&:hover': {
     backgroundColor: '#3cd4a1'
   }
 },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide
    }
  },
  ecommerceIcon: {
    color: "#6E6E6E"
  },
  calendarIcon: {
    color: theme.palette.primary.main,
    marginRight: 14
  },
  margin: {
    marginBottom: 24
  },
  changeThemeFab: {
    position: "fixed",
    top: theme.spacing(15),
    right: 0,
    zIndex: 1,
    borderRadius: 0,
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%"
  },
  breadCrumbPointer:{
    cursor:"pointer"
  },
  marginLeftAuto:{
    marginLeft: "auto"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));
