import {
  PENDING,
  SUCCESS,
  FAILED,
  SIGN_IN_BEGIN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILED,
  SIGN_UP_FAILED,
  CLEAR_MESSAGE,
  SESSION_EXPIRED,
  SESSION_EXPIRED_SUCCESS,
  SIGN_IN_WITH_GOOGLE_BEGIN,
  SIGN_IN_WITH_GOOGLE_SUCCESS,
  SIGN_IN_WITH_GOOGLE_FAILED,
  SIGN_OUT_BEGIN,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILED,
  UPDATE_USER_PROFILE_BEGIN,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_SUCCESS,
  INVITED_USER_REGISTER_BEGIN,
  INVITED_USER_REGISTER_SUCCESS,
  INVITED_USER_REGISTER_FAILED,
  FETCH_INVITED_USER_LIST_BEGIN,
  FETCH_INVITED_USER_LIST_SUCCESS,
  FETCH_INVITED_USER_LIST_FAILED,
  FILTER_INVITED_USER_LIST,
  DELETE_USER_INVITATION_BEGIN,
  DELETE_USER_INVITATION_SUCCESS,
  DELETE_USER_INVITATION_FAILED,
  SLACK_INTEGRATION_BEGIN,
  SLACK_INTEGRATION_SUCCESS,
  SLACK_INTEGRATION_FAILED,
  DELETE_SLACK_INTEGRATION_BEGIN,
  DELETE_SLACK_INTEGRATION_SUCCESS,
  DELETE_SLACK_INTEGRATION_FAILED,
  INTEGRATION_CONFIG_BEGIN,
  INTEGRATION_CONFIG_SUCCESS,
  INTEGRATION_CONFIG_FAILED,
  GET_SLACK_INTEGRATION_FAILED,
  GET_SLACK_INTEGRATION_BEGIN,
  GET_SLACK_INTEGRATION_SUCCESS,
  UPDATE_USER_PROFILES_BEGIN,
  UPDATE_USER_PROFILES_FAILED,
  UPDATE_USER_PROFILES_SUCCESS,
  SET_LOGO_IMAGE_URL,
} from "../_constants";
import { slackIntegration } from "../_actions/user.action";

const initialState = {
  status: "",
  userData: {},
  token: "",
  invitedUserList: [],
  invitedUserListClone: [],
  errorMessage: "",
  successMessage: "",
  isAuthenticated: false,
  isSignOut: false,
  integrations: [],
  selectedIntegration: {},
  clonedIntegrations: [],
  logoImageUrl: '',
  logoThumbnailImageUrl: '',
  logoNotSet: '',
};

export default (state = initialState, action) => {
  let integrations;
  switch (action.type) {
    case SIGN_IN_BEGIN:
      return {
        ...state,
        status: PENDING,
        userData: "",
        token: "",
        errorMessage: "",
        isAuthenticated: false,
        successMessage: ""
      };

    case SIGN_IN_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        userData: action.data.userData,
        token: action.data.token,
        errorMessage: "",
        isAuthenticated: true,
        successMessage: action.data.messages
      };

    case SIGN_IN_FAILED:
      return {
        ...state,
        status: FAILED,
        errorMessage: action.data,
        userData: "",
        token: "",
        isAuthenticated: false,
        successMessage: ""
      };

    case SIGN_IN_WITH_GOOGLE_BEGIN:
      return {
        ...state,
        status: PENDING,
        userData: "",
        errorMessage: "",
        isAuthenticated: false,
        successMessage: ""
      };

    case SIGN_IN_WITH_GOOGLE_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        userData: action.data.userData,
        token: action.data.token,
        isAuthenticated: true,
        successMessage: action.data.messages
      };

    case SIGN_IN_WITH_GOOGLE_FAILED:
      return {
        ...state,
        status: FAILED,
        errorMessage: action.data,
        userData: "",
        isAuthenticated: false,
        successMessage: ""
      };

    case SIGN_UP_FAILED:
      return {
        ...state,
        status: FAILED,
        errorMessage: action.data,
        successMessage: ""
      };

    case INVITED_USER_REGISTER_BEGIN:
      return {
        ...state,
        status: PENDING,
        userData: "",
        errorMessage: "",
        successMessage: ""
      };

    case INVITED_USER_REGISTER_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        userData: action.data.user,
        token: action.data.token,
        errorMessage: "",
        successMessage: action.data.success
      };

    case INVITED_USER_REGISTER_FAILED:
      return {
        ...state,
        status: FAILED,
        errorMessage: action.data,
        successMessage: ""
      };

    case DELETE_USER_INVITATION_BEGIN:
      return {
        ...state,
        status: PENDING,
        errorMessage: "",
        successMessage: ""
      };

    case DELETE_USER_INVITATION_SUCCESS:
      let userList = state.invitedUserList;
      userList.slice(0);
      const selectedUsers = action.data.userIds;
      if (selectedUsers.length) {
        selectedUsers.map(selectedUser => {
          const userIndex = userList.findIndex(user => {
            return user.id === selectedUser;
          });
          if (userIndex > -1) {
            return userList.splice(userIndex, 1);
          }
        });
      }
      return {
        ...state,
        status: SUCCESS,
        invitedUserList: userList,
        invitedUserListClone: userList,
        token: action.data.token,
        errorMessage: "",
        successMessage: action.data.success
      };

    case DELETE_USER_INVITATION_FAILED:
      return {
        ...state,
        status: FAILED,
        errorMessage: action.data,
        successMessage: ""
      };

    case FETCH_INVITED_USER_LIST_BEGIN:
      return {
        ...state,
        status: PENDING
      };

    case FETCH_INVITED_USER_LIST_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        invitedUserList: action.data,
        invitedUserListClone: action.data
      };

    case FETCH_INVITED_USER_LIST_FAILED:
      return {
        ...state,
        status: FAILED,
        errorMessage: action.data,
        successMessage: ""
      };

    case FILTER_INVITED_USER_LIST:
      const searchValue = action.data;
      let updatedUserList = state.invitedUserListClone;
      if (searchValue) {
        updatedUserList = updatedUserList.filter(user => {
          return (
            user &&
            user.email &&
            user.email.toLowerCase().search(searchValue.toLowerCase()) !== -1
          );
        });
      } else {
        updatedUserList = state.invitedUserListClone;
      }
      return {
        ...state,
        status: SUCCESS,
        invitedUserList: updatedUserList
      };

    case SIGN_OUT_BEGIN:
      return {
        ...state,
        status: PENDING,
        userData: "",
        errorMessage: "",
        isAuthenticated: false,
        successMessage: "",
        isSignOut: false
      };

    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        userData: action.data.userData,
        errorMessage: "",
        isAuthenticated: false,
        token: "",
        successMessage: action.data.message,
        isSignOut: true
      };

    case SIGN_OUT_FAILED:
      return {
        ...state,
        status: FAILED,
        errorMessage: "",
        userData: "",
        isAuthenticated: false,
        successMessage: "",
        isSignOut: false
      };

    case UPDATE_USER_PROFILE_BEGIN:
      return {
        ...state,
        status: PENDING,
        errorMessage: "",
        successMessage: ""
      };

    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        userData: action.data.user,
        errorMessage: "",
        successMessage: action.data.success
      };

    case UPDATE_USER_PROFILE_FAILED:
      return {
        ...state,
        status: FAILED,
        errorMessage: action.data,
        successMessage: ""
      };

    case CLEAR_MESSAGE:
      return {
        ...state,
        status: "",
        errorMessage: "",
        successMessage: "",
        isSignOut: false
      };

    case SESSION_EXPIRED:
      return {
        ...state,
        status: SESSION_EXPIRED_SUCCESS,
        errorMessage: "Access denied!. Token has expired.",
        userData: "",
        successMessage: "",
        isSignOut: true

      };

    case SLACK_INTEGRATION_BEGIN:
      return {
        ...state,
        status: PENDING
      };

    case SLACK_INTEGRATION_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        integrations: action.data,
        clonedIntegrations: action.data
      };

    case SLACK_INTEGRATION_FAILED:
      return {
        ...state,
        status: FAILED
      };

    case GET_SLACK_INTEGRATION_BEGIN:
      return {
        ...state,
        status: PENDING
      };

    case GET_SLACK_INTEGRATION_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        selectedIntegration: action.data
      };

    case GET_SLACK_INTEGRATION_FAILED:
      return {
        ...state,
        status: FAILED
      };

    case DELETE_SLACK_INTEGRATION_BEGIN:
      return {
        ...state
      };
    case DELETE_SLACK_INTEGRATION_SUCCESS:
      integrations = state.integrations;
      integrations.slice(0);
      const selectedIntegration = action.integrationId;
      const integrationIndex = integrations.findIndex(
        integration => integration.integration_id === selectedIntegration
      );
      if (integrationIndex > -1) {
        // integrations.splice(integrationIndex, 1);
        integrations[integrationIndex].is_auth = false;
      }
      return {
        ...state,
        status: "SLACK DELETED",
        integrations: integrations,
        clonedIntegrations: integrations
      };
    case DELETE_SLACK_INTEGRATION_FAILED:
      return {
        ...state
      };

    case INTEGRATION_CONFIG_BEGIN:
      return {
        ...state
      };
    case INTEGRATION_CONFIG_SUCCESS:
      // if (index > -1) {
      //   integrations[index].status =
      //   integrations[index].status === "false" ? "true" : "false";
      //   integrations[index].is_auth = null;
      //   integrations[index].integration_id = null;
      // }
      return {
        ...state,
        status: "UPDATED",
        selectedIntegration: action.integration
        // integrations,
        // clonedIntegrations: integrations
      };
    case INTEGRATION_CONFIG_FAILED:
      return {
        ...state
      };
    case UPDATE_USER_PROFILES_BEGIN:
      return {
        ...state,
        status: PENDING,
        errorMessage: "",
        successMessage: ""
      };

    case UPDATE_USER_PROFILES_SUCCESS:

      return {
        ...state,
        status: SUCCESS,
        logoImageUrl: action.data.url,
        logoThumbnailImageUrl: action.data.thumbnailUrl,
        logoNotSet: action.data.logoNotSet,
        errorMessage: "",
        successMessage: action.data.success
      };

    case UPDATE_USER_PROFILES_FAILED:
      return {
        ...state,
        status: FAILED,
        errorMessage: action.data,
        successMessage: ""
      };
    case SET_LOGO_IMAGE_URL:
      return {
        ...state,
        logoImageUrl: action.value,
      };
    default:
      return state;
  }
};
