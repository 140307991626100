import React, { Fragment, useState, useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import Icon from "@mdi/react";
import {
  mdiSettings as SettingsIcon,
  mdiFacebookBox as FacebookIcon,
  mdiTwitterBox as TwitterIcon,
  mdiGithubBox as GithubIcon,
} from "@mdi/js";
import {
  Fab,
  IconButton,
  Popper,
  FormControlLabel,
  RadioGroup,
  Box,
  Radio,
  Grid,
  Breadcrumbs,
  Tabs,
  Tab,
  Checkbox,
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
} from "@material-ui/core";
import {
  NavigateNext as NavigateNextIcon,
  CalendarToday as CalendarIcon,
  ChatBubbleOutline as ChatIcon,
  AddShoppingCart as AddIcon,
  StarBorder as StarIcon,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import Helmet from "react-helmet";
import loadExternalScript from "../../common/loadScript";
// styles
import useStyles from "./styles";
import { isAuthenticated } from "../../common/isAuthenticated";
// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import Widget from "../Widget";
import { Link, Typography, Button } from "../../components/Wrappers";
import SidebarStructure from "../Sidebar/SidebarStructure";

// pages
import Dashboard from "../../pages/dashboard";
import TypographyPage from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import LineCharts from "../../pages/charts/LineCharts";
import BarCharts from "../../pages/charts/BarCharts";
import PieCharts from "../../pages/charts/PieCharts";
import Colors from "../../pages/colors";
import GridPage from "../../pages/grid";
import Badge from "../../pages/badge";
import Carousel from "../../pages/сarousel";
import Modal from "../../pages/modal";
import Navbar from "../../pages/nav/Navbar";
import Tooltips from "../../pages/tooltips";
import TabsPage from "../../pages/tabs";
import FormsElements from "../../pages/forms/elements";
import FormValidation from "../../pages/forms/validation";
import Cards from "../../pages/cards";
import DynamicTables from "../../pages/tables/dynamic";
import WidgetPage from "../../pages/widget";
import Progress from "../../pages/progress";
import Ecommerce from "../../pages/ecommerce";
import Product from "../../pages/ecommerce/Products";
import ProductsGrid from "../../pages/ecommerce/ProductsGrid";
import MapsGoogle from "../../pages/maps";
import VectorMaps from "../../pages/maps/VectorMap";
import Timeline from "../../pages/timeline";
import Search from "../../pages/search";
import Gallery from "../../pages/gallery";
import Invoice from "../../pages/invoice";
import CreateVariable from "../../pages/ecommerce/CreateVariable";
import Calendar from "../../pages/calendar";
import Installation from "../../pages/installation/installation";
import Integrations from "../../pages/integrations/integrations";
import Settings from "../../pages/company_settings/settings";
import Breadcrumb from "../../pages/breadcrumb/breadcrumb";
// context
import { useLayoutState } from "../../context/LayoutContext";
import { useThemeDispatch } from "../../context/ThemeContext";
import Loader from "../../common/loader"
//Sidebar structure
import structure from "../Sidebar/SidebarStructure";
import Profile from "../../pages/profile/profile";
import UserInvitation from "../../pages/user/invitation";
import InvitedUserList from "../../pages/user/invitedUserList";
import RetentionFlowsList from "../../pages/retentionFlows/retentionFlowsList";
import FlowStep from "../../pages/retentionFlows/flowStep";
import { Component } from "@fullcalendar/core";
import IntegrationSetup from "../../pages/integrations/IntegrationSetup";
import { useSelector } from "react-redux"
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch } from "react-redux";
import {
  requestedCancellation,
  completedCancellation,
  submittedReason,
  cancelledReason,
  allCancellationsList,
  selectedPagination,
} from "../../Redux/_actions/dashboard.action";
import store from '../../Redux/_store/index'
// Tab styling
const CustomTab = withStyles((theme) => ({
  root: {
    minWidth: 72,
  },
}))((props) => <Tab {...props} />);

const TitleComponent = ({ title }) => {
  var defaultTitle = "ChurnAI";
  return (
    <Helmet>
      <title>{title ? title : defaultTitle}</title>
    </Helmet>
  );
};

const withTitle = ({ component: Component, title }) => {
  return (props) => (
    <>
      <TitleComponent title={title}></TitleComponent>
      <Component {...props}></Component>
    </>
  );
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function Layout(props) {
  const classes = useStyles();
  const num = parseInt(localStorage.getItem('dateSelection'))
  const [value, setValue] = React.useState(num ? num : 2);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleChangeTheme = (e) => {
    localStorage.setItem("theme", e.target.value);
    themeDispatch({ type: "TOGGLE_COLOR_THEME", theme: e.target.value });
  };
  const open = Boolean(anchorEl);
  const id = open ? "add-section-popover" : undefined;
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const user = isAuthenticated();
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // global
  var layoutState = useLayoutState();
  var themeDispatch = useThemeDispatch();
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  let path = props.history.location.pathname
  useEffect(() => {
    if (path === '/flows') {
      setFlag(false)
    }
  }, [path]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("dateSelection", newValue);
  };
  const dispatch = useDispatch();
  const [flowName, setFlowName] = React.useState([]);
  const [flowIds, setFlowIds] = React.useState([]);
  const [names, setNames] = React.useState('')
  const [flowId, setFlowId] = React.useState('')
  const [flagRemove, setFlagRemove] = React.useState(false)
  const handleCheckboxChange = event => {
    if (event.target.value !== undefined)
      setFlowName(event.target.value);
  };
  const handleCheckboxSelectChange = event => {
    let duplicateFlow
    duplicateFlow = flowIds.filter(id => id == event.target.value)
    if (Object.values(duplicateFlow).length <= 0) {
      const ids = flowIds
      const data = parseInt(event.target.value)
      setFlowId(data)
      ids.push(data)
      setFlowIds(ids);
    } else {
      const removeFlowId = flowIds.findIndex(id => id == duplicateFlow[0])
      const id = flowIds
      id.splice(removeFlowId, 1)
      setFlowIds(id);
      setFlagRemove(true)
    }
  };
  const handleSelectClose = () => {
    const state = store.getState();
    let pageNo
    if (flowIds.length > 0) {
      pageNo = 1
      dispatch(selectedPagination(1));
    } else {
      pageNo = state.analytics.selectedPagination
    }
    const order = state.analytics.defaultOrder
    const datatype = value + 1
    dispatch(requestedCancellation(true, datatype, flowIds));
    dispatch(completedCancellation(true, datatype, flowIds));
    dispatch(submittedReason(true, datatype, flowIds));
    dispatch(cancelledReason(true, datatype, flowIds));
    dispatch(allCancellationsList(true, datatype, flowIds, pageNo, order));

  }

  const resetPageNo = () => {
    if (flowIds.length > 0) {

    }
  }
  const storeData = () => {
    const state = store.getState();
    const flowData = state.flows.flowList
    setNames(flowData)
  }
  useEffect(() => {
    if (flowId !== '') {
      flowDataName()
    }
  }, [flowId])
  useEffect(() => {
    if (flagRemove) {
      removeFlowData()
    }
  }, [flagRemove])
  useEffect(() => {
    if (flowName.length > 0) {
      const flowIndex = flowName.findIndex(name => name == undefined)
      const flowRemove = flowName.splice(flowIndex, 1)
    }
  }, [flowName])
  const removeFlowData = () => {
    const state = store.getState();
    const flowsData = state.flows.flowList
    const removeFlow = flowsData.filter(({ id }) => id == flowId)
    const flow = flowName.findIndex(name => name == removeFlow[0].name)
    const flowData = flowName
    flowData.splice(flow, 1)
    setFlowName(flowData);
    setFlagRemove(false)
  }
  const flowDataName = () => {
    const state = store.getState();
    const flowData = state.flows.flowList
    const selectedFlow = Object.values(flowData).filter(({ id }) => id == flowId)
    const selectedFlowName = selectedFlow[0].name
    const flow_name = flowName
    flow_name.push(selectedFlowName)
    setFlowName(flow_name)
  }
  const handleSelectOpen = () => {
    storeData()
  }
  const popUp = () => {
    setLoading(true);
    const flowId = props.location.state.flowId;
    loadExternalScript({
      src:
        "https://rawgit.com/Marak/faker.js/master/examples/browser/js/faker.js",
      id: "faker",
    }).then((loaded) => {
      if (loaded) {
        loadExternalScript({
          src: `${process.env.REACT_APP_API_SERVER_URL}/widget/v1/load.js`,
          id: "churnLoader",
        }).then((loaded) => {
          if (loaded) {
            if (window.ChurnAI) {
              window.ChurnAI.config({
                write_key: `${user && user.company_id}`, //*  Install ID provided by ChurnAI
                first_name: window.faker.name.firstName(), //   Users' First Name
                last_name: window.faker.name.lastName(), //   Users' Last Name
                email: window.faker.internet.email(), //* Users' email address
                flow_id: flowId,
                account: {
                  name: window.faker.company.companyName(), // * Display name of company for end-user facing content
                  domain: window.faker.internet.domainName(), //   Used for display and data enrichment
                  id: window.faker.random.alphaNumeric(), // * Unique company ID
                  billing_id: window.faker.random.alphaNumeric(), //   Your user's billing ID used in your billing system
                  plan: "enterprise", //   Plan type name used in your billing system
                  mrr: 100000, //   Subscription revenue value (monthly or annual)
                  created_at: 1312182000, //   Timestamp of account created date
                },
                custom: {
                  plan_name: "Supreme", //   custom variable 1
                  storage_capacity: "25GB", //   custom variable 2
                },
                demo: true,
              });
              window.ChurnAI.init();
              setLoading(false)
            }
          }
        });
      }
    });
  };

  //BreadCrumbs
  function goToParentPage(path) {
    const history = props.history;
    history.push(path);
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const breadcrumb =
    props.history.location.state && props.history.location.state;
  const parentPath = window.location.pathname.split("/").filter((x) => x);
  let isOutOfSidebarStructure = SidebarStructure.find((c) => {
    return c.link !== window.location.pathname && !c.children;
  });
  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Sidebar structure={structure} />
      <div
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        <div className={classes.fakeToolbar} />
        <Widget disableWidgetMenu inheritHeight className={classes.margin}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            wrap={"nowrap"}
            style={{ overflow: "auto" }}
          >
            {SidebarStructure.map((c) => {
              // if (window.location.pathname.includes(c.link)) {
              if (
                c.link !== undefined &&
                c.children === undefined &&
                c.link === window.location.pathname
              ) {
                if (c.link && c.link === "/") {
                  return (
                    <Fragment key={c.id}>
                      <Helmet>
                        <title>{c.label}</title>
                      </Helmet>
                      <Box display="flex" alignItems="center">
                        <Breadcrumbs aria-label="breadcrumb">
                          <Typography variant="h4">{c.label}</Typography>
                        </Breadcrumbs>
                        {(window.location.pathname === "/" ||
                          window.location.pathname === "") && (
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="simple tabs example"
                              variant="scrollable"
                              scrollButtons="auto"
                              style={{ marginLeft: 38 }}
                            >
                              <CustomTab label="Today" {...a11yProps(0)} />
                              <CustomTab label="This week" {...a11yProps(1)} />
                              <CustomTab label="This month" {...a11yProps(2)} />
                              <CustomTab label="This year" {...a11yProps(3)} />
                            </Tabs>
                          )}
                      </Box>
                    </Fragment>
                  );
                } else {
                  return (
                    <Box display="flex" alignItems="center" key={c.id}>
                      <Breadcrumbs aria-label="breadcrumb" key={c.id}>
                        <Typography variant="h4">{c.label}</Typography>
                      </Breadcrumbs>
                    </Box>
                  );
                }
              }
              // else if (
              //   breadcrumb !== undefined &&
              //   c.link.includes(parentPath[0])
              // ) {
              //   {
              //     isOutOfSidebarStructure = false;
              //   }
              //   return (
              //     <Box display="flex" alignItems="center" key={c.id}>
              //       <Breadcrumbs
              //         separator={<NavigateNextIcon fontSize="small" />}
              //         aria-label="breadcrumb"
              //         key={c.id}
              //         className={classes.breadCrumbPointer}
              //       >
              //         <Typography
              //           onClick={() => goToParentPage(c.link)}
              //           variant={"h6"}
              //         >
              //           {c.label}
              //         </Typography>
              //         <Breadcrumb breadcrumb={breadcrumb} />
              //       </Breadcrumbs>
              //     </Box>
              //   );
              // }
              else {
                if (c.link === undefined && c.children) {
                  c.children.map((child) => {
                    if (child.link == window.location.pathname) {
                      return (
                        <Box display="flex" alignItems="center">
                          <Breadcrumbs aria-label="breadcrumb">
                            <Typography variant="h4">{child.label}</Typography>
                          </Breadcrumbs>
                        </Box>
                      );
                    }
                  });
                  // }
                }
              }
              // }
            })}
            {isOutOfSidebarStructure && parentPath.length > 1 && (
              <Box style={{ width: "100%" }} display="flex">
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography variant="h4">
                    {breadcrumb && breadcrumb.label}
                  </Typography>
                </Breadcrumbs>
                {
                  breadcrumb && breadcrumb.label === 'Flow Update' &&
                  <Button
                    variant="contained"
                    className={`${classes.PreviewButton} ${classes.marginLeftAuto}`}
                    onClick={() => {
                      popUp();
                      // setFlag(true);
                    }
                    }
                  >
                    PREVIEW FLOW
              </Button>
                }

              </Box>
            )}
            {window.location.pathname === "/" && (
              <Box display="flex" alignItems="center">
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-mutiple-checkbox-label">All Flows</InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={flowName}
                    onChange={handleCheckboxChange}
                    input={<Input />}
                    onClose={handleSelectClose}
                    onOpen={handleSelectOpen}
                    renderValue={selected => selected.join(",")}
                    MenuProps={MenuProps}
                  >
                    {names !== '' && names.map((flow, index) => {
                      const even = (element) => element == flow.id;
                      let data1 = flowIds.some(even)
                      return <div key={flow.id}>
                        <Checkbox value={flow.id} onChange={handleCheckboxSelectChange} checked={data1} />
                        {flow.name}
                        <br />
                      </div>
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}
            {/* {window.location.pathname !== "/" && breadcrumb === undefined || isOutOfSidebarStructure &&(
              <Box display="flex" alignItems="center">
                <Box>
                  <IconButton aria-label="chat">
                    <ChatIcon className={classes.ecommerceIcon} />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton aria-label="add_to_cart">
                    <AddIcon className={classes.ecommerceIcon} />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton aria-label="rate">
                    <StarIcon className={classes.ecommerceIcon} />
                  </IconButton>
                </Box>
              </Box>
            )} */}
          </Grid>
        </Widget>
        {
          loading ?
            <Dialog
              open={loading}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <Loader visible={loading} />
              </DialogContent>
            </Dialog> : ""
        }
        <Switch>
          <Route path="/app/typography" component={TypographyPage} />
          <Route path="/app/core/grid" component={GridPage} />
          <Route path="/app/ui/notifications" component={Notifications} />
          <Route path="/app/forms/elements" component={FormsElements} />
          <Route path="/app/forms/validation" component={FormValidation} />
          <Route path="/app/ui/badge" component={Badge} />
          <Route path="/app/ui/carousel" component={Carousel} />
          <Route path="/app/ui/modal" component={Modal} />
          <Route path="/app/ui/navbar" component={Navbar} />
          <Route path="/app/ui/tooltips" component={Tooltips} />
          <Route path="/app/ui/tabs" component={TabsPage} />
          <Route path="/app/ui/cards" component={Cards} />
          <Route path="/app/ui/widget" component={WidgetPage} />
          <Route path="/app/ui/progress" component={Progress} />
          <Route path="/app/tables/static" component={Tables} />
          <Route path="/app/tables/dynamic" component={DynamicTables} />
          <Route path="/app/charts/overview" component={Charts} />
          <Route path="/app/charts/line" component={LineCharts} />
          <Route path="/app/charts/bar" component={BarCharts} />
          <Route path="/app/charts/pie" component={PieCharts} />
          <Route
            path="/variables/new"
            component={withTitle({
              component: CreateVariable,
              title: "Create a New Variable",
            })}
          />
          <Route
            path="/variables/:id/edit"
            component={withTitle({
              component: CreateVariable,
              title: "Edit Variable Name",
            })}
          />
          <Route
            path="/variables"
            component={withTitle({ component: Ecommerce, title: "Variables" })}
          />
          <Route
            path="/install"
            component={withTitle({
              component: Installation,
              title: "Installation",
            })}
          />
          <Route
            exact
            path="/integrations"
            component={withTitle({
              component: Integrations,
              title: "Integrations",
            })}
          />
          <Route
            path="/integrations/:name"
            component={withTitle({
              component: IntegrationSetup,
              title: "Integrations",
            })}
          />

          <Route
            exact
            path="/settings"
            component={withTitle({
              component: Settings,
              title: "Settings",
            })}
          />

          <Route
            path="/profile"
            component={withTitle({ component: Profile, title: "Profile" })}
          />
          <Route path="/users/new" component={UserInvitation} />
          <Route path="/users" component={InvitedUserList} />
          {/* <Route path="/flows/new" component={FlowStep} /> */}
          <Route
            path="/flows/:id/edit"
            component={withTitle({
              component: FlowStep,
              title: "Edit Flow Name",
            })}
          />
          {
            !flag && <Route
              path="/flows"
              component={withTitle({
                component: RetentionFlowsList,
                title: "Flows",
              })}
            />
          }
          <Route path="/app/ecommerce/product/:id" component={Product} />
          <Route path="/app/ecommerce/product" component={Product} />
          <Route path="/app/ecommerce/gridproducts" component={ProductsGrid} />
          <Route
            exact
            path="/app/tables"
            render={() => <Redirect to={"/app/tables/static"} />}
          />
          <Route
            exact
            path="/app/charts"
            render={() => <Redirect to={"/app/charts/overview"} />}
          />
          <Route
            exact
            path="/app/ui"
            render={() => <Redirect to="/app/ui/icons" />}
          />
          <Route
            exact
            path="/app/core"
            render={() => <Redirect to="/app/core/typography" />}
          />
          <Route
            exact
            path="/app/forms"
            render={() => <Redirect to="/app/forms/elements" />}
          />
          <Route
            exact
            path="/app/ecommerce"
            render={() => <Redirect to="/app/ecommerce/management" />}
          />
          <Route
            exact
            path="/app/extra"
            render={() => <Redirect to="/app/extra/timeline" />}
          />
          <Route
            exact
            path="/app/maps"
            render={() => <Redirect to="/app/maps/google" />}
          />
          <Route path="/app/extra/timeline" component={Timeline} />
          <Route path="/app/extra/search" component={Search} />
          <Route path="/app/extra/gallery" component={Gallery} />
          <Route path="/app/extra/invoice" component={Invoice} />
          <Route path="/app/extra/calendar" component={Calendar} />
          <Route path="/app/core/colors" component={Colors} />
          <Route path="/app/maps/google" component={MapsGoogle} />
          <Route path="/app/maps/vector" component={VectorMaps} />
          <Route path="/app/ui/icons" component={Icons} />
          <Route
            path="/"
            exact
            render={() => <Dashboard selected_flow_Ids={flowIds} selectedDateType={value + 1} />}
          />
        </Switch>
        <Fab
          color="primary"
          aria-label="settings"
          onClick={(e) => handleClick(e)}
          className={classes.changeThemeFab}
        >
          <Icon path={SettingsIcon} size={1} color="#fff" />
        </Fab>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement={"left-start"}
        >
          <Widget disableWidgetMenu>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body2" weight={"bold"}>
                COLOR THEME
              </Typography>
              <RadioGroup
                aria-label="theme"
                value={localStorage.getItem("theme")}
                onChange={(e) => handleChangeTheme(e)}
              >
                <Box display="flex" justifyContent="space-between">
                  <FormControlLabel
                    className={classes.noMargin}
                    value="default"
                    control={<Radio className={classes.defaultRadio} />}
                  />
                  <FormControlLabel
                    className={classes.noMargin}
                    value="secondary"
                    control={<Radio className={classes.secondaryRadio} />}
                  />
                  <FormControlLabel
                    className={classes.noMargin}
                    value="success"
                    control={<Radio className={classes.successRadio} />}
                  />
                </Box>
              </RadioGroup>
            </Box>
          </Widget>
        </Popper>
        <Footer>
          <div>
            <Link
              color={"primary"}
              href={"https://churn.ai"}
              target={"_blank"}
              className={classes.link}
            >
              ChurnAI LLC.
            </Link>
            <Link
              color={"primary"}
              href={"https://churn.ai/about"}
              target={"_blank"}
              className={classes.link}
            >
              About Us
            </Link>
          </div>
          {/* <div>
            <Link href={"https://www.facebook.com/flatlogic"} target={"_blank"}>
              <IconButton aria-label="facebook">
                <Icon path={FacebookIcon} size={1} color="#6E6E6E99" />
              </IconButton>
            </Link>
            <Link href={"https://twitter.com/flatlogic"} target={"_blank"}>
              <IconButton aria-label="twitter">
                <Icon path={TwitterIcon} size={1} color="#6E6E6E99" />
              </IconButton>
            </Link>
            <Link href={"https://github.com/flatlogic"} target={"_blank"}>
              <IconButton
                aria-label="github"
                style={{ padding: "12px 0 12px 12px" }}
              >
                <Icon path={GithubIcon} size={1} color="#6E6E6E99" />
              </IconButton>
            </Link>
          </div> */}
        </Footer>
      </div>
    </div>
  );
}

export default withRouter(Layout);
