import {
    SET_BREADCRUMB_NAME,
} from "../_constants";


export const setBreadcrumb = (breadcrumb) => async dispatch => {
    dispatch({
        type: SET_BREADCRUMB_NAME,
        name: breadcrumb
    });
}; 