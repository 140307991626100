import React, { useEffect } from "react";
import { Grid, Card, CardContent, CardMedia, Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Typography, Button } from "../../components/Wrappers";
import useStyles from "./styles";
import { isAuthenticated } from "../../common/isAuthenticated";

//actions
import { slackIntegration } from "../../Redux/_actions/user.action";

export function Integrations(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const slackIntegrationsSelector = useSelector(
    state => state.userData.integrations
  );
  const user = isAuthenticated();

  toast.configure({
    autoClose: 4000,
    draggable: true
  });

  useEffect(() => {
    dispatch(slackIntegration());
  }, []);

  const company_id = user.company_id;
  const classes = useStyles(props);

  function setupIntegration(integration) {
    history.push(`/integrations/${integration.slug}`, {
      label: integration.name,
      setup: true,
      integration
    });
  }

  function editIntegration(integration) {
    history.push(`/integrations/${integration.slug}`, {
      label: integration.name
      // integration
    });
  }

  const url =
    `https://slack.com/oauth/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}` +
    `&scope=incoming-webhook,chat:write:bot&user_scope=identify&state=${company_id}`;

  return (
    <div>
      <Grid container spacing={4} className={classes.gridC}>
        {slackIntegrationsSelector.length &&
          slackIntegrationsSelector.map((integration, index) => {
            return (
              // <Grid item md={4} sm={11} key={index}>
              //   <Card className={classes.cardMedia}>
              //     <CardMedia className={classes.media}>
              //       <img
              //         src={integration.image_url}
              //         className={classes.media}
              //       />
              //     </CardMedia>
              //     <CardContent>
              //       <Typography gutterBottom variant="h5" component="h2">
              //         {integration.name}
              //       </Typography>

              //       <Typography
              //         variant="body2"
              //         color="textSecondary"
              //         component="p"
              //         className={classes.cardContent}
              //       >
              //         {integration.description}
              //       </Typography>
              //       {isConfigure && selectedConfigureIndex === index && (
              //         <Typography>
              //           Channel slack is posting to: {integration.channel}
              //         </Typography>
              //       )}
              //       <Divider className={classes.divider}></Divider>
              //       {!isSetup &&(<Button variant="contained"
              //             color="secondary"
              //             onClick={()=>{setup(integration.name,index)}}>Setup
              //       </Button>)}
              //       <br></br>

              //       { !isConfigure ?  (
              //         integration.integration_id && integration.is_auth ? (
              //           <Grid container spacing={4}>
              //             {integration.status === "active" ? (
              //               <Grid item xs={6}>
              //                 <Typography>Status:</Typography>
              //                 <Chip
              //                   className={classes.badge}
              //                   color="primary"
              //                   label={"Enabled"}
              //                 />
              //               </Grid>
              //             ) : (
              //               <Grid item xs={6}>
              //                 <Typography>Status:</Typography>
              //                 <Chip
              //                   className={classes.badge}
              //                   color="secondary"
              //                   label={"Disabled"}
              //                 />
              //               </Grid>
              //             )}
              //             <Grid item xs={6} className={classes.switchBtn}>
              //               <Button
              //                 variant="contained"
              //                 color="secondary"
              //                 style={{ marginLeft: "auto" }}
              //                 className={classes.setupButton}
              //                 onClick={() =>
              //                   configuration(integration.integration_id, index)
              //                 }
              //               >
              //                 Configure
              //               </Button>
              //             </Grid>
              //           </Grid>
              //         ) : (
              //         isSetup &&
              //            {slackImageButton}
              //         )
              //       ) : selectedConfigureIndex === index ? (
              //         <Grid container spacing={4} className={classes.gridC}>
              //           <Grid item xs={6}>
              //             <Button
              //               size="small"
              //               color="secondary"
              //               variant="contained"
              //               className={classes.uninstall}
              //               onClick={() => {
              //                 showDeletePopUp(integration.integration_id);
              //               }}
              //             >
              //               Uninstall
              //             </Button>
              //           </Grid>
              //           <Grid item xs={6}>
              //             <Switch
              //               id="enabled"
              //               color="primary"
              //               checked={integration.status === "active"}
              //               inputProps={{
              //                 "aria-label": "primary checkbox"
              //               }}
              //               onClick={() => {
              //                 saveIntegration(
              //                   integration.integration_id,
              //                   integration.status
              //                 );
              //               }}
              //             />
              //           </Grid>
              //         </Grid>
              //       ) : (
              //         isSetup &&
              //           {slackImageButton}
              //       )}
              //     </CardContent>
              //   </Card>
              // </Grid>

              <Grid item md={4} sm={11} key={index}>
                <Card className={classes.cardMedia}>
                  <CardMedia>
                    <img
                      src={integration.image_url}
                      className={classes.media}
                    />
                  </CardMedia>
                  <CardContent>
                    {/* <Typography gutterBottom variant="h5" component="h2">
                      {integration.name}
                    </Typography> */}

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className={classes.cardContent}
                    >
                      {integration.description}
                    </Typography>
                    <Divider className={classes.divider}></Divider>

                    <Grid item sm={12} className={classes.setupButton}>
                      {integration && !integration.is_auth ? (
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => {
                            setupIntegration(integration);
                          }}
                        >
                          Setup
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            editIntegration(integration);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}
const mapStateToProps = props => ({
  userData: props.userData
});
export default connect(mapStateToProps, null)(Integrations);
