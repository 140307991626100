import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { FlowChartWithState, LinkDefault } from "@mrblenny/react-flow-chart";
import {
  Grid,
  Switch,
  FormControl,
  Input,
  Checkbox,
  Box,
  Card,
  CardContent,
  IconButton,
  ListItem,
  List,
  ListItemText,
  Tab,
  Tabs,
  AppBar,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import './custom.css';
import useStyles from "./styles";
import {
  History as HistoryIcon,
  VisibilityOff as VisibilityOffIcon,
  AttachMoney as AttachMoneyIcon,
  HelpOutline as HelpOutlineIcon,
  Translate as TranslateIcon,
  Timeline as TimelineIcon,
  BugReport as BugReportIcon,
  Timer as TimerIcon,
  Healing as HealingIcon,
  Highlight as HighlightIcon,
  FindReplace as FindReplaceIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import Typography from '@material-ui/core/Typography';
import Widget from "../../components/Widget/Widget";
import { isAuthenticated } from "../../common/isAuthenticated";
import {
  updateFlow,
  clearFlowStateMsg,
  setSelectedFlow,
  updateFlowStep,
  updateAddOffers,
} from "../../Redux/_actions/flow.action";
import { setBreadcrumb } from "../../Redux/_actions/breadcrumb.action";
import { axiosRequest } from "../../Redux/_requests";
import { toast } from "react-toastify";
import qs from "qs";
// text Editor imports
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import Markdown from 'markdown-to-jsx';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism-dark.css';
import { Button } from "../../components/Wrappers/Wrappers"
import { Sandbox, withDependencies } from 'react-sandbox-editor'
const arrayToTree = require('array-to-tree');
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

let smartRouting = true;
const FlowStep = props => {
  const classes = useStyles();
  const user = isAuthenticated();
  const dispatch = useDispatch();
  const [flow, setFlow] = useState({ flowName: "", enabled: false });
  const [flowId, setFlowId] = useState(null);
  const [steps, setSteps] = useState({ steps: [], inital: false });
  const [lastOpen, setLastOpen] = useState('');
  const [dragIndex, setDragIndex] = useState(null);
  const [offer, setOffer] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [hoverId, setHoverId] = useState(null);
  const [offerHoverId, setOfferHoverId] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  const [defaultOffers, setDefaultOffers] = React.useState([]);
  const [offerTypes] = React.useState([
    { name: 'None', value: 'none' },
    { name: 'Call Me', value: 'call' },
    { name: 'Link Somewhere', value: 'link' },
    { name: 'Give a Discount', value: 'discount' },
    { name: 'Pause My Subscription', value: 'pause' },
  ]);
  const [code, setCode] = React.useState('');
  const getFlowId = () => {
    setFlowId(props.match.params.id);
    if (flowId) {
      fetchFlowById(flowId);
      fetchDefaultOffers();
    }
  }

  let specialCharacterRemove
  let regex = /[*,#,>]/gi;
  let stepDescription
  let custom_Css = false
  useEffect(getFlowId, [flowId]);
  const fetchFlowById = async (flowId) => {
    const fetchFlowResponse = await axiosRequest(
      "GET",
      `flows/${flowId}`,
      true,
      null,
      null,
      null,
      dispatch
    );
    if (fetchFlowResponse.status === 200) {
      const flow = fetchFlowResponse.data;
      dispatch(setSelectedFlow(flow));
    }
  }
  const fetchDefaultOffers = async () => {
    const fetchOffers = await axiosRequest(
      "GET",
      `offers/defaults`,
      true,
      null,
      null,
      null,
      dispatch
    );
    if (fetchOffers.status === 200) {
      setDefaultOffers(fetchOffers.data);
    }
  }
  const setFormValue = (flow) => {
    if (Object.keys(flow).length !== 0) {
      if (flow.name !== props.breadCrumb) {
        dispatch(setBreadcrumb(flow.name));
      }
      setFlow({ flowName: flow.name, enabled: flow.enabled });
      setCode(flow.custom_css)
      setSteps({ steps: flow.steps, inital: steps.inital });
    }
  }

  const flowOperationSuccess = () => {
    if (props && props.flows && props.flows.status === "SUCCESS") {
      if (isValid === true) {
        setFormValue(props.flows.selectedFlow);
      }
      dispatch(clearFlowStateMsg());
    } else if (
      props &&
      props.flows &&
      props.flows.status === "FAILED" &&
      props.flows.errorMessage &&
      props.flows.errorMessage !== ""
    ) {
      toast.error(props.flows.errorMessage);
      dispatch(clearFlowStateMsg());
    }
  }

  useEffect(flowOperationSuccess, [props.selectedFlow]);

  useEffect(() => {
    return () => {
      dispatch(setBreadcrumb(''));
      dispatch(setSelectedFlow({}));
      setFlow({ flowName: "", enabled: false });
      setFlowId(null);
      setSteps({ steps: [], inital: false });
      setLastOpen('');
      setDragIndex(null);
      setOffer(null);
      setParentId(null);
      setHoverId(null);
      setOfferHoverId(null);
      setIsValid(true);
      setIsFullScreen(true);
      setTabValue(0);
    };
  }, [dispatch]);

  /**
  *@createPorts - In which we create the flow diagram nodes ports.
  */
  const createPorts = (x, data, status = false, isOffer = false) => {
    const tempArray = {};
    const parentInfo = data.filter(k => k.id === x.parent_id);
    if (!status && x.parent_id !== null && x.parent_id !== 0) {
      const filterrData = data.filter(j => j.id === x.parent_id &&
        j.form_type === 'reason' && j.step_reasons);
      if (filterrData.length) {
        const enabledChild = filterrData[0].step_reasons.filter(j => j.enabled === true);
        if (enabledChild.length) {
          if (x.children !== undefined && x.children.length) {
            tempArray[`port${x.children[0].id + x.children[0].form_type}`] = {
              id: `port${x.children[0].id + x.children[0].form_type}`,
              type: "input",
              properties: {
                value: 'no',
              },
            };
          }
          tempArray[`port${x.id + x.form_type}`] = {
            id: `port${x.id + x.form_type}`,
            type: "input",
            properties: {
              value: 'no',
            },
          };
        } else if (x.parent_id !== null && x.parent_id !== 0 && parentInfo.length > 0) {
          tempArray[`port${x.parent_id + parentInfo[0].form_type}`] = {
            id: `port${x.parent_id + parentInfo[0].form_type}`,
            type: "input",
            properties: {
              value: 'no',
            },
          };
        }
      } else if (x.parent_id !== null && x.parent_id !== 0 && parentInfo.length > 0) {
        tempArray[`port${x.parent_id + parentInfo[0].form_type}`] = {
          id: `port${x.parent_id + parentInfo[0].form_type}`,
          type: "input",
          properties: {
            value: 'no',
          },
        };
      }
    } else if (status && x.parent_id !== null && x.parent_id !== 0 && parentInfo.length > 0) {
      tempArray[`port${x.parent_id + parentInfo[0].form_type}`] = {
        id: `port${x.parent_id + parentInfo[0].form_type}`,
        type: "input",
        properties: {
          value: 'no',
        },
      };
    }
    if (x.children && x.children.length > 0 && x.form_type === 'reason') {
      if (isOffer === false) {
        tempArray[`port${x.id + x.form_type}`] = {
          id: `port${x.id + x.form_type}`,
          type: "output",
          properties: {
            value: 'yes',
          },
        }
      }
    } else {
      x.children && x.children.map((j) =>
        (isOffer === false) ? tempArray[`port${j.id + j.form_type}`] = {
          id: `port${j.id + j.form_type}`,
          type: "output",
          properties: {
            value: 'yes',
          },
        } : '');
    }
    if (isOffer) {
      tempArray['port1'] = {
        id: 'port1',
        type: "output",
        properties: {
          value: 'yes',
        },
      };
    }
    return tempArray;
  }
  /**
  *@findLevel - In which we are extracting the level between parent and child.
  */
  const findLevel = (arr, level = 0) => {
    return arr.reduce(function (levelled, toLevel) {
      levelled = levelled.concat({ "id": toLevel.id, "level": level, ...toLevel });
      if (toLevel.children) {
        return levelled.concat(findLevel(toLevel.children, (level + 1)));
      }
      return levelled;
    }, []);
  }
  /**
  *
  *@createFlowChart - In which we create the flow chart.
  */
  const createFlowChart = (data, hoverId) => {
    const createNodesLinking = {};
    const createNodes = {};
    let yPostionAdd = 0;

    data.map(function (x, i) {
      /** Create nodes links code start */
      if (x.form_type !== 'reason' && !x.step_reasons) {
        x.children && x.children.map((j) =>
          createNodesLinking[`link${x.id + x.form_type}`] = {
            id: `link${x.id + x.form_type}`,
            from: {
              nodeId: `node${x.id + x.form_type}`,
              portId: `port${j.id + j.form_type}`,
            },
            to: {
              nodeId: `node${j.id + j.form_type}`,
              portId: `port${x.id + x.form_type}`
            }
          })
      } else if (x.form_type === 'reason' && x.step_reasons) {
        const enabledChild = x.step_reasons.filter(j => j.enabled === true);
        smartRouting = (enabledChild.length === 1) ? false : true;
        if (enabledChild.length) {
          const finalEnabledChild = enabledChild.map(function (el) {
            const o = Object.assign({}, el);
            o.form_type = 'customOption';
            o.parent_id = x.id;
            o.isOffer = (el.offer !== void 0 && el.offer.offer_type !== void 0 && el.offer.offer_type !== 'none' && el.enabled) ? true : false;
            o.children = x.children;
            return o;
          });
          const isOfferenabledChild = finalEnabledChild.filter(j => j.isOffer === true);
          finalEnabledChild.map((j) =>
            createNodesLinking[`customlink${j.id + j.form_type}`] = {
              id: `customlink${j.id + j.form_type}`,
              from: {
                nodeId: `node${x.id + x.form_type}`,
                portId: `port${x.id + x.form_type}`,
              },
              to: {
                nodeId: `node${j.id + j.form_type}`,
                portId: `port${x.id + x.form_type}`
              }
            })
          finalEnabledChild.map(function (j, index) {
            if (j.children !== undefined && j.isOffer === false) {
              createNodesLinking[`customlinkOutput${j.id + j.form_type}`] = {
                id: `customlinkOutput${j.id + j.form_type}`,
                from: {
                  nodeId: `node${j.id + j.form_type}`,
                  portId: `port${j.children[0].id + j.children[0].form_type}`,
                },
                to: {
                  nodeId: `node${j.children[0].id + j.children[0].form_type}`,
                  portId: `port${j.children[0].id + j.children[0].form_type}`
                }
              }
            } else if (j.isOffer === true) {
              createNodesLinking[`customlinkOutput${j.id + j.form_type}`] = {
                id: `customlinkOutput${j.id + j.form_type}`,
                from: {
                  nodeId: `node${j.id + j.form_type}`,
                  portId: `port1`,
                },
                to: {
                  nodeId: `node${j.id + j.form_type}_first`,
                  portId: `port1`
                }
              }
              createNodesLinking[`customlinkOutput${j.id + j.form_type}_first`] = {
                id: `customlinkOutput${j.id + j.form_type}_first`,
                from: {
                  nodeId: `node${j.id + j.form_type}_first`,
                  portId: `port2`,
                },
                to: {
                  nodeId: `node${j.id + j.form_type}_second`,
                  portId: `port1`
                }
              }
              createNodesLinking[`customlinkOutput${j.id + j.form_type}_second${index}`] = {
                id: `customlinkOutput${j.id + j.form_type}_second${index}`,
                from: {
                  nodeId: `node${j.id + j.form_type}_second`,
                  portId: `port2`,
                },
                to: {
                  nodeId: `node${j.id + j.form_type}_third`,
                  portId: `port1`
                },
                properties: {
                  label: "Yes"
                }
              }
              if (x.children !== void 0 && x.children.length > 0) {
                createNodesLinking[`customlinkOutputt${j.id + j.form_type}_second${index}`] = {
                  id: `customlinkOutput${j.id + j.form_type}_second${index}`,
                  from: {
                    nodeId: `node${j.id + j.form_type}_second`,
                    portId: `port${x.children[0].id + x.children[0].form_type}`,
                  },
                  to: {
                    nodeId: `node${x.children[0].id + x.children[0].form_type}`,
                    portId: `port${x.children[0].id + x.children[0].form_type}`
                  },
                  properties: {
                    label: (finalEnabledChild.length === 2 && index > 0) ? '' : "No",
                    child: finalEnabledChild.length,
                    childOffer: isOfferenabledChild.length,
                    index: index
                  }
                }
              }
            }
            return false;
          })
        } else {
          x.children && x.children.map((j) =>
            createNodesLinking[`link${x.id + x.form_type}`] = {
              id: `link${x.id + x.form_type}`,
              from: {
                nodeId: `node${x.id + x.form_type}`,
                portId: `port${x.id + x.form_type}`,
              },
              to: {
                nodeId: `node${j.id + j.form_type}`,
                portId: `port${x.id + x.form_type}`
              }
            })
        }
      }
      /** Create nodes links Code End */

      /** Create dynamic nodes Start */
      const isReason = (x.step_reasons) ? true : false;
      if (x.form_type !== 'reason' && !x.step_reasons) {
        createNodes[`node${x.id + x.form_type}`] = {
          id: `node${x.id + x.form_type}`,
          type: {
            name: 'parent',
            data: x,
            isOffer: false,
          },
          position: {
            x: 500,
            y: (x.level === 0) ? 20 : (150 * ((isReason) ? (i + .2) : i)) + yPostionAdd + 50
          },
          ports: createPorts(x, data),
        };
      } else if (x.form_type === 'reason' && x.step_reasons) {
        const enabledChild = x.step_reasons.filter(j => j.enabled === true);
        if (enabledChild.length) {
          const finalEnabledChild = enabledChild.map(function (el) {
            const o = Object.assign({}, el);
            o.form_type = 'customOption';
            o.parent_id = x.id;
            o.isOffer = (el.offer !== void 0 && el.offer.offer_type !== void 0 && el.offer.offer_type !== 'none' && el.enabled) ? true : false;
            o.children = x.children;
            return o;
          });
          const isOfferenabledChild = finalEnabledChild.filter(j => j.isOffer === true);
          createNodes[`node${x.id + x.form_type}`] = {
            id: `node${x.id + x.form_type}`,
            type: {
              name: 'parent',
              data: x,
              isOffer: false
            },
            position: {
              x: 500,
              y: (x.level === 0) ? 20 : 150 * ((isReason) ? (i + .2) : i)
            },
            ports: createPorts({
              ...x,
              children: finalEnabledChild
            }, data),
          };
          let xPostionMultiPly = 155;
          let xPostion = 2;
          switch (enabledChild.length) {
            case 1:
              xPostion = 615;
              break;
            case 2:
              xPostion = 450;
              break;
            case 3:
              xPostion = 450;
              break;
            case 4:
              xPostion = 320;
              break;
            case 5:
              xPostion = 320;
              break;
            case 6:
              xPostion = 200;
              break;
            case 7:
              xPostion = 165;
              break;
            case 8:
              xPostion = 100;
              break;
            default:
              xPostion = 40;
              break
          }
          if (enabledChild.length === 2) {
            xPostionMultiPly = 380;
          }
          if (enabledChild.length === 3) {
            xPostionMultiPly = 180;
          }
          if (enabledChild.length === 4 && (isOfferenabledChild.length === 3 || isOfferenabledChild.length === 4)) {
            xPostionMultiPly = 200;
          }
          const yPostion = (x.level === 0) ? 60 : 150 * ((isReason) ? (i + .2) : i) + 200;
          finalEnabledChild.map(function (l, i) {
            createNodes[`node${l.id + l.form_type}`] = {
              id: `node${l.id + l.form_type}`,
              type: {
                name: 'child',
                isOffer: false,
                parent: x,
                data: l
              },
              position: {
                x: (i === 0 && finalEnabledChild.length !== 1) ?
                  ((finalEnabledChild.length === 4) ? xPostion : xPostion - 10) : xPostion + ((i > 0) ? i * xPostionMultiPly : 15),
                y: (x.level === 0) ? 180 : yPostion
              },
              ports: createPorts({
                ...l,
                children: x.children
              }, data, true, l.isOffer),
            };
            return true;
          });
          if (isOfferenabledChild.length > 0) {
            finalEnabledChild.map(function (l, i) {
              let tempNumberXFirst = 0;
              if (finalEnabledChild.length === 3) {
                tempNumberXFirst = (i === 0) ? 130 : 80;
              }
              if (l.isOffer) {
                createNodes[`node${l.id + l.form_type + '_first'}`] = {
                  id: `node${l.id + l.form_type + '_first'}`,
                  type: {
                    name: 'child',
                    isOffer: true,
                    step: 0,
                    parent: x,
                    data: l
                  },
                  position: {
                    x: (((i === 0 && finalEnabledChild.length !== 1) ?
                      ((finalEnabledChild.length === 4) ? xPostion - 40 : xPostion - 10) : xPostion + ((i > 0) ? i * xPostionMultiPly : 15))) - tempNumberXFirst,
                    y: (x.level === 0) ? 290 : yPostion + 120
                  },
                  ports: {
                    port1: {
                      id: "port1",
                      type: "input",
                      properties: {
                        value: 'no',
                      },
                    },
                    port2: {
                      id: "port2",
                      type: "output",
                      properties: {
                        value: 'yes',
                      },
                    },
                  },
                };
                let tempChild = {};

                if (x.children !== void 0 && x.children.length > 0) {
                  tempChild[`port${x.children[0].id}${x.children[0].form_type}`] = {
                    id: `port${x.children[0].id}${x.children[0].form_type}`,
                    type: (isOfferenabledChild.length > 2 && i > 1) ? 'left' : 'right',
                  };
                }
                let tempNumberX = 0;
                if (finalEnabledChild.length > 5 && finalEnabledChild.length <= 7) {
                  tempNumberX = 65;
                }

                if (finalEnabledChild.length === 3) {
                  tempNumberX = (i === 0) ? 130 : 80;
                }

                if (enabledChild.length === 4 && isOfferenabledChild.length === 3 && i > 1) {
                  tempNumberX = -65;
                }
                createNodes[`node${l.id + l.form_type + '_second'}`] = {
                  id: `node${l.id + l.form_type + '_second'}`,
                  type: {
                    name: 'child',
                    isOffer: true,
                    step: 1,
                    parent: x,
                    data: l
                  },
                  position: {
                    x: ((((i === 0 && finalEnabledChild.length !== 1) ?
                      ((finalEnabledChild.length === 4) ? xPostion - 40 : xPostion - 10) : xPostion + ((i > 0) ? i * xPostionMultiPly : 15)) + 16) - tempNumberX),
                    y: (x.level === 0) ? 150 + 270 : yPostion + 270
                  },
                  ports: {
                    port1: {
                      id: "port1",
                      type: "input",
                      properties: {
                        value: 'no',
                      },
                    },
                    port2: {
                      id: "port2",
                      type: "output",
                      properties: {
                        value: 'yes',
                      },
                    },
                    ...tempChild
                  }
                };
                if (finalEnabledChild.length > 5 && finalEnabledChild.length <= 7 && i === 1) {
                  tempNumberX = 80;
                }
                if (finalEnabledChild.length === 3) {
                  tempNumberX = (i === 0) ? 145 : 80;
                }
                createNodes[`node${l.id + l.form_type + '_third'}`] = {
                  id: `node${l.id + l.form_type + '_third'}`,
                  type: {
                    name: 'child',
                    isOffer: true,
                    step: 2,
                    parent: x,
                    data: l
                  },
                  position: {
                    x: ((i === 0 && finalEnabledChild.length !== 1) ?
                      (((finalEnabledChild.length !== 4 && finalEnabledChild.length > 3 && i === 0) ? (xPostion - 25) : (finalEnabledChild.length === 4) ? xPostion - 55 : xPostion - 10)) :
                      (finalEnabledChild.length > 7 && i <= 1) ? ((xPostion + ((i > 0) ? i * xPostionMultiPly : 15)) - ((i === 0) ? 25 : 15)) : xPostion + ((i > 0) ? i * xPostionMultiPly : 15)) - tempNumberX,
                    y: (x.level === 0) ? 110 + 460 : yPostion + 460
                  },
                  ports: {
                    port1: {
                      id: "port1",
                      type: "input",
                      properties: {
                        value: 'no',
                      },
                    },
                  }
                };
              }
              return true;
            });
            yPostionAdd = (x.level === 0) ? 500 : 620;
          } else {
            yPostionAdd = 200;
          }

        } else {
          createNodes[`node${x.id + x.form_type}`] = {
            id: `node${x.id + x.form_type}`,
            type: {
              name: 'parent',
              data: x,
              isOffer: false,
            },
            position: {
              x: 500,
              y: (x.level === 0) ? 20 : 160 * ((isReason) ? (i + .2) : i)
            },
            ports: createPorts(x, data),
          }
        } return true;
      }
      /** Create dynamic nodes Code End */
      return true
    });
    return {
      offset: {
        x: 0,
        y: 0
      },
      nodes: createNodes,
      links: createNodesLinking,
      selected: {},
      hovered: {}
    };
  }
  const setValue = (event, step, setStep) => {
    setStep({
      ...step,
      [event.target.id]:
        event.target.id === "enabled" ||
          event.target.id === "terms_and_condition_checked"
          ? event.target.checked
          : event.target.value
    });
  }

  const setValueStep = (value, id, key) => {
    const data = steps.steps;
    const index = data.findIndex(x => x.id === id);
    if (index > -1) {
      data[index][key] = value;
      setSteps({ steps: data, inital: steps.inital });
    }
  }

  const selectingReason = (index, event, stepId) => {
    const data = steps.steps;
    const indexx = data.findIndex(x => x.id === stepId);
    if (indexx > -1) {
      data[indexx]['step_reasons'][index]['enabled'] = event.target.checked;
      setSteps({ steps: data, inital: (steps.inital) ? false : true });
      const formData = {
        "steps[step_reasons_attributes]": {
          id: data[indexx]['step_reasons'][index].id,
          enabled: event.target.checked
        }
      };
      dispatch(
        updateFlowStep(
          true,
          stepId,
          index,
          indexx,
          null,
          qs.parse(formData),
          data[indexx]['step_reasons']
        )
      );
    }
  }

  const openTab = (info) => {
    afterOnClick(info.id);
  }

  const afterOnClick = (name) => {
    if (name === 'close') {
      setLastOpen('');
      setOffer(null);
      setParentId(null);
      setIsFullScreen(true);
    } else if (name) {
      setLastOpen(name);
      setIsFullScreen(false);
    }
  }
  const openSubTab = (data, parentId) => {
    const newdata = steps.steps.filter(x => x.id === parentId)[0];
    const offerData = newdata.step_reasons.filter(x => x.id === data.id)[0];
    setOffer(offerData);
    setParentId(parentId);
    setLastOpen('');
    setIsFullScreen(false);
    setOfferHoverId(null)
  }

  const saveFlow = () => {
    const company_id = user.company_id;
    const css = localStorage.getItem('CustomCss')
    let formData
    if (code !== '') {
      formData = {
        "flow[name]": flow.flowName.trim(),
        "flow[enabled]": flow.enabled,
        "flow[company_id]": company_id,
        "flow[custom_css]": css
      };
    }
    else {
      formData = {
        "flow[name]": flow.flowName.trim(),
        "flow[enabled]": flow.enabled,
        "flow[company_id]": company_id
      };
    }
    dispatch(updateFlow(true, flowId, null, qs.parse(formData)));
    localStorage.removeItem('CustomCss')
  }
  const saveStep = (event, id) => {
    const stepIndex = steps.steps.findIndex(x => x.id === id);
    const reasonIndex = steps.steps.findIndex(x => x.name === 'Reasons' && x.id === id);
    if (stepIndex > -1) {
      const step_reasons = (reasonIndex > 0) ? steps.steps[reasonIndex]['step_reasons'] : null;
      const controlId = event.target.name;
      const controlValue =
        event.target.name === "enabled" ||
          event.target.name === "terms_and_condition_checked"
          ? event.target.checked
          : event.target.value.trim();
      const formData = new FormData();
      formData.append(`steps[name]`, steps.steps[stepIndex].name);
      formData.append(`steps[${controlId}]`, controlValue);
      dispatch(
        updateFlowStep(true, id, stepIndex, (reasonIndex > -1) ? reasonIndex : null, null, formData, step_reasons)
      );
    }
  }

  const setOfferStep = (value, type) => {
    const data = steps.steps;
    const stepIndex = data.findIndex(x => x.id === parentId);
    if (stepIndex > -1) {
      const reasonIindex = steps.steps[stepIndex].step_reasons.findIndex(x => x.id === offer.id);
      if (reasonIindex > -1) {
        data[stepIndex].step_reasons[reasonIindex].offer[type] = value;
        setOffer(data[stepIndex].step_reasons[reasonIindex])
        setSteps({ steps: data, inital: steps.inital });
      }
    }
  }

  const setOfferStepChange = async (value, type) => {
    const stepData = steps.steps;
    const stepIndex = stepData.findIndex(x => x.id === parentId);
    if (stepIndex > -1) {
      const reasonIindex = steps.steps[stepIndex].step_reasons.findIndex(x => x.id === offer.id);
      const index = defaultOffers.findIndex(x => x.offer_type === value);
      if (reasonIindex > -1 && index > -1) {
        const data = defaultOffers[index];
        const formData = new FormData();
        formData.append(`offers[offer_type]`, data.offer_type);
        formData.append(`offers[title]`, data.title);
        formData.append(`offers[description]`, data.description);
        formData.append(`offers[next_text]`, data.next_text);
        formData.append(`offers[decline_text]`, data.decline_text);
        formData.append(`offers[accepted_title]`, data.accepted_title);
        formData.append(`offers[accepted_description]`, data.accepted_description);
        formData.append(`offers[accepted_dismiss_text]`, data.accepted_dismiss_text);
        formData.append(`offers[accepted_dismiss_action]`, data.accepted_dismiss_action);
        const upDateOffer = await saveUpdateOffer("PATCH", `offers/${stepData[stepIndex].step_reasons[reasonIindex].offer.id}`, formData);
        if (upDateOffer.status === 200) {
          stepData[stepIndex].step_reasons[reasonIindex][`offer`] = upDateOffer.data;
          setSteps({ steps: stepData, inital: (steps.inital) ? false : true });
          if (value === 'none') {
            setLastOpen('');
            setOffer(null);
            setParentId(null);
            setIsFullScreen(true);

          }
        }
      }
    }
  }
  useEffect(() => {
    localStorage.removeItem('CustomCss')
  }, [custom_Css])

  const setOfferStepOuter = async (value, type, reasonId, parentId) => {
    const stepData = steps.steps;
    const stepIndex = stepData.findIndex(x => x.id === parentId);
    if (stepIndex > -1) {
      const reasonIindex = steps.steps[stepIndex].step_reasons.findIndex(x => x.id === reasonId);
      if (reasonIindex > -1) {
        const index = defaultOffers.findIndex(x => x.offer_type === value);
        if (index > -1 && stepData[stepIndex].step_reasons[reasonIindex].offer === void 0) {
          const data = defaultOffers[index];
          const formData = new FormData();
          formData.append(`offers[flow_id]`, flowId);
          formData.append(`offers[offer_type]`, data.offer_type);
          formData.append(`offers[title]`, data.title);
          formData.append(`offers[description]`, data.description);
          formData.append(`offers[next_text]`, data.next_text);
          formData.append(`offers[decline_text]`, data.decline_text);
          formData.append(`offers[accepted_title]`, data.accepted_title);
          formData.append(`offers[accepted_description]`, data.accepted_description);
          formData.append(`offers[accepted_dismiss_text]`, data.accepted_dismiss_text);
          formData.append(`offers[accepted_dismiss_action]`, data.accepted_dismiss_action);
          formData.append(`step_reason_id`, reasonId);
          const addOffers = await saveUpdateOffer("POST", `offers`, formData);
          if (addOffers.status === 200) {
            stepData[stepIndex].step_reasons[reasonIindex][`offer`] = addOffers.data;
            setSteps({ steps: stepData, inital: (steps.inital) ? false : true });
          }
        } else if (index > -1 && stepData[stepIndex].step_reasons[reasonIindex].offer !== void 0) {
          const data = defaultOffers[index];
          const formData = new FormData();
          formData.append(`offers[offer_type]`, data.offer_type);
          formData.append(`offers[title]`, data.title);
          formData.append(`offers[description]`, data.description);
          formData.append(`offers[next_text]`, data.next_text);
          formData.append(`offers[decline_text]`, data.decline_text);
          formData.append(`offers[accepted_title]`, data.accepted_title);
          formData.append(`offers[accepted_description]`, data.accepted_description);
          formData.append(`offers[accepted_dismiss_text]`, data.accepted_dismiss_text);
          formData.append(`offers[accepted_dismiss_action]`, data.accepted_dismiss_action);
          const upDateOffer = await saveUpdateOffer("PATCH", `offers/${stepData[stepIndex].step_reasons[reasonIindex].offer.id}`, formData);
          if (upDateOffer.status === 200) {
            stepData[stepIndex].step_reasons[reasonIindex][`offer`] = upDateOffer.data;
            setSteps({ steps: stepData, inital: (steps.inital) ? false : true });
          }

        }
      }
    }
  }
  const saveUpdateOffer = async (method, url, formData) => {
    const data = await axiosRequest(
      method,
      url,
      true,
      null,
      formData,
      null,
      dispatch
    );
    return data;
  }



  const saveOffer = (value, type, id) => {
    const formData = new FormData();
    formData.append(`offers[${type}]`, value);
    dispatch(
      updateAddOffers(
        true,
        id,
        formData,
        null
      )
    );
  }

  const dragOver = (event) => {
    event.preventDefault();
  }

  const dragDrop = (event, index) => {
    event.preventDefault();
    const dropIndex = index;
    const data = steps.steps;
    const newData = data[dragIndex];
    data.splice(dragIndex, 1);
    data.splice(dropIndex, 0, newData);
    const tempArray = [];
    setIsValid(false);
    data.map(function (x, index) {
      tempArray.push({
        ...x,
        parent_id: (index === 0) ? null : data[index - 1].id
      });
      const formData = new FormData();
      formData.append(`steps[name]`, x.name);
      formData.append(`steps[parent_id]`, (index === 0) ? null : data[index - 1].id);
      dispatch(
        updateFlowStep(true, x.id, index, null, null, formData)
      );
      if (index === data.length - 1) {
        return new Promise(function (resolve) {
          setTimeout(() => {
            fetchFlowById(flowId);
            resolve.bind(setIsValid(true))
          }, 200)
        });
      }
      return false;
    });
    Promise.resolve().then(function () {
      setSteps({ steps: tempArray, inital: (steps.inital) ? false : true });
    });
  }

  const dragStart = (index) => {
    setDragIndex(index);
  }

  const onHoverCard = (id) => {
    setHoverId(id);
  }

  const textArea = data => (<Card className={`${classes.nodeWrap}`}>
    <CardContent onMouseEnter={() => onHoverCard(data.id)} onMouseLeave={() => onHoverCard(null)} className="p-0" >
      <Typography component="h2"
        className={`${classes.nodeTitle} ${classes.nodeTitleCustomize}  ${(!data.enabled) ? classes.flowOpactiy : ''} ${data.name.replace(' ', '').toLowerCase()}`}>
        {data.title.trim().length > 48 ? data.title.trim().substring(0, 45) + "..." : data.title.trim()}  {(hoverId === data.id) ? <span className={classes.editWrapper} onClick={() => openTab(data)}>
          <EditIcon className={classes.editIcon} /></span> : ''}
      </Typography>
      <Typography component="div" className={` ${(!data.enabled) ? classes.flowOpactiy : ''} ${classes.nodeDesc}`}>
        {
          specialCharacterRemove = data.description,
          stepDescription = specialCharacterRemove.replace(regex, '').trim().length > 192 ? specialCharacterRemove.replace(regex, '').substr(0, 192) + "..." : specialCharacterRemove.replace(regex, '')
        }
      </Typography></CardContent></Card>);

  const textAreaOption = (x, data, parentId) => (<Card className={`${classes.nodeChildWrap}`} onMouseEnter={() => (data.offer !== void 0 && data.offer.offer_type !== void 0 && data.offer.offer_type !== 'none') ?
    setOfferHoverId(data.id) : ''} onMouseLeave={() => (data.offer !== void 0 && data.offer.offer_type !== void 0 && data.offer.offer_type !== 'none') ? setOfferHoverId(null) : ''}>
    <Typography component="h2"
      className={`${classes.nodeChildTitle} ${(!x.enabled) ? classes.flowOpactiy : ''} ${classes.nodeTitleCustomize} ${x.name.replace(' ', '').toLowerCase()}`}>
      {data.name.trim().length > 20 ? data.name.trim().substring(0, 15) + "..." : data.name.trim()}  {(offerHoverId === data.id) ? <span className={classes.editWrapper} onClick={() => openSubTab(data, parentId)}>
        <EditIcon className={classes.editIcon} /></span> : ''}
    </Typography>
    <CardContent className={`p-1 ${classes.nodeDesc} ${(!x.enabled) ? classes.flowOpactiy : ''}`} >

      <Grid className={classes.nodeDescIcon}>
        {selectIcon(data)}
      </Grid>
    </CardContent></Card>)

  const textAreaOffer = (parentData, data) => (<Card className={`${classes.nodeChildWrap} `}>
    <CardContent className="p-0">
      <Typography component="h2"
        className={`${classes.nodeChildTitle} ${(!parentData.enabled) ? classes.flowOpactiy : ''} ${classes.nodeTitleCustomize} offer`}>
        {data.title.length > 20 ? data.title.substring(0, 18) + "..." : data.title}
      </Typography>
      <Typography className={`${classes.nodeDesc} ${(!parentData.enabled) ? classes.flowOpactiy : ''}`}> {data.description.length > 58 ? data.description.substring(0, 55) + "..." : data.description}</Typography></CardContent></Card>);


  const textAreaOfferCondition = data => (<div className={`${classes.offerNode}`}><div className={`${(!data.enabled) ? 'diamond_off' : 'diamond'}`}></div></div>);

  const selectIcon = (data) => (<>
    {data.icon === "history" && (
      <HistoryIcon
        className={classes.reasonIcon}
      />
    )}
    {data.icon === "visibility_off" && (
      <VisibilityOffIcon
        className={classes.reasonIcon}
      />
    )}
    {data.icon === "attach_money" && (
      <AttachMoneyIcon
        className={classes.reasonIcon}
      />
    )}
    {data.icon === "help_outline" && (
      <HelpOutlineIcon
        className={classes.reasonIcon}
      />
    )}
    {data.icon === "translate" && (
      <TranslateIcon
        className={classes.reasonIcon}
      />
    )}
    {data.icon === "find_replace" && (
      <FindReplaceIcon
        className={classes.reasonIcon}
      />
    )}
    {data.icon === "timeline" && (
      <TimelineIcon
        className={classes.reasonIcon}
      />
    )}
    {data.icon === "icon-needs" && (
      <HelpOutlineIcon
        className={classes.reasonIcon}
      />
    )}
    {data.icon === "icon-bugs" && (
      <BugReportIcon
        className={classes.reasonIcon}
      />
    )}
    {data.icon === "timer" && (
      <TimerIcon className={classes.reasonIcon} />
    )}
    {data.icon === "healing" && (
      <HealingIcon className={classes.reasonIcon} />
    )}
    {data.icon === "highlight" && (
      <HighlightIcon
        className={classes.reasonIcon}
      />
    )}
  </>
  )


  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };


  const rightSideBar = findLevel(arrayToTree(steps.steps));
  const data = createFlowChart(rightSideBar, hoverId);
  const NodeInnerCustom = ({ node }) => {
    if (node.type.name === 'parent' && data !== void 0) {
      const data = steps.steps.filter(x => x.id === node.type.data.id);
      if (data.length > 0) {
        return textArea(data[0])
      } else {
        return true;
      }


    } else {
      const data = steps.steps.filter(x => x.id === node.type.parent.id);
      if (node.type.isOffer === false && data.length > 0) {
        return textAreaOption(data[0], node.type.data, node.type.parent.id)
      } else if (node.type.isOffer === true && data.length > 0) {
        const offerData = data !== void 0 && data[0].step_reasons && data[0].step_reasons.filter(x => x.id === node.type.data.id);
        if (offerData !== void 0 && offerData.length > 0) {
          return (node.type.step === 1) ? textAreaOfferCondition(data[0]) :
            (node.type.step === 2) ?
              textAreaOffer(data[0], { title: offerData[0].offer.accepted_title, description: offerData[0].offer.accepted_description }) :
              textAreaOffer(data[0], offerData[0].offer);
        } else {
          return true;
        }
      }
    }
  }

  const PortCustom = (props) => (
    <span className={classes.customPort}>
      {props.port.properties && props.port.properties.value === 'no' && (
        <svg style={{ width: '30px', height: '30px' }} viewBox="0 0 24 24">
          <path fill="cornflowerblue" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </svg>
      )}    </span>
  )
  const textEditor = (event, id) => {
    const stepIndex = steps.steps.findIndex(x => x.id === id);
    const reasonIndex = steps.steps.findIndex(x => x.name === 'Reasons' && x.id === id);
    if (stepIndex > -1) {
      const step_reasons = (reasonIndex > 0) ? steps.steps[reasonIndex]['step_reasons'] : null;
      const description = steps.steps;
      const controlId = event
      const controlValue = description[stepIndex].description
      const formData = new FormData();
      formData.append(`steps[name]`, steps.steps[stepIndex].name);
      formData.append(`steps[${controlId}]`, controlValue);
      dispatch(
        updateFlowStep(true, id, stepIndex, (reasonIndex > -1) ? reasonIndex : null, null, formData, step_reasons)
      );
    }
  }
  // custome css add method
  toast.configure({
    autoClose: 5000,
    draggable: true
  });
  useEffect(() => {
    if (code == null) {
      setCode('')
    }
  }, [code])
  const customCss = () => {
    setCode(localStorage.getItem('CustomCss'))
    cssMethod()
  }
  const cssMethod = () => {
    if (code !== '') {
      saveFlow();
      toast.success('Custom css set successfully.', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }
  const ReactSandbox = withDependencies([
    'https://unpkg.com/react@16.6.0/umd/react.development.js',
    'https://unpkg.com/react-dom@16.6.0/umd/react-dom.development.js'
  ])(Sandbox)
  const handleChange = (value, event) => {
    if (value == 'stylesheet') { localStorage.setItem('CustomCss', event) }
  }
  return (
    <>
      <Grid container spacing={3}>
        <Grid sm={12} item>

          <Widget disableWidgetMenu inheritHeight className={classes.relativePosistion}>

            <AppBar position="static">

              <Tabs value={tabValue} onChange={handleChangeTab}
                indicatorColor="primary"
                textColor='primary'
                variant="fullWidth"
                className={classes.tabHeader}
                aria-label="simple tabs example">
                <Tab label="Flow | Steps" {...a11yProps(0)} />
                <Tab label="Flow Settings" {...a11yProps(1)} />
                <Tab label="Analytics" {...a11yProps(2)} />
              </Tabs>
            </AppBar>

            <TabPanel value={tabValue} index={0} className={classes.tabPanel}>
              {data !== void 0 && steps.steps.length > 0 && <FlowChartWithState
                config={{ smartRouting: smartRouting, readonly: true }}
                key={steps.inital} initialValue={data}
                Components={{
                  Link: (props) => {
                    const { startPos } = props
                    let centerX = startPos.x + 4 +
                      ((props.link.properties !== void 0 && props.link.properties.child !== void 0 && (props.link.properties.child === 2) &&
                        props.link.properties.label.toLowerCase() === 'no') ? 125 : 1) -
                      ((props.link.properties !== void 0 && props.link.properties.childOffer > 2 && props.link.properties.index > 1 && props.link.properties.label.toLowerCase() === 'no') ? 40 : 0);
                    const centerY = startPos.y + 1 + ((props.link.properties !== void 0 && props.link.properties.child !== void 0 && (props.link.properties.child === 2) && props.link.properties.label.toLowerCase() === 'no') ? -30 : 1)
                    return (
                      <>
                        <LinkDefault {...props} />
                        {props.link.properties && props.link.properties.label && (
                          <div style={{
                            left: centerX,
                            top: (props.link.properties.label.toLowerCase() === 'no') ? centerY + 5 : centerY
                          }} className={classes.linkLabelParent}>
                            <div className={classes.linkLabel}>{props.link.properties.label}</div>
                          </div>
                        )}

                      </>
                    )
                  },
                  NodeInner: NodeInnerCustom,
                  Port: PortCustom,
                }}
              />}
              {
                (!isFullScreen) ?
                  <Box className={classes.panelRight}>
                    <Typography
                      className={classes.widgetCustomizeTitle}
                    ><IconButton onClick={() => afterOnClick('close')} ><CloseIcon /></IconButton></Typography>
                    {
                      lastOpen !== '0' && (rightSideBar.filter(x => x.id === lastOpen)).map((step, index) =>
                        <Grid container alignItems="center" key={index} >

                          {
                            (step['enabled'] !== undefined) ?
                              <>
                                <Grid item xs={12} sm={4} className="mb-4">
                                  <Typography className={classes.fontWeight500}>Enabled</Typography>
                                </Grid>
                                <Grid item xs={12} sm={8} className="mb-4">
                                  <Switch
                                    id={`${step.id}enabled${index}`}
                                    name="enabled"
                                    checked={(step.enabled) ? true : false}
                                    onChange={() => setValueStep((step.enabled) ? false : true, step.id, 'enabled')}
                                    color="primary"
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                    onBlur={(e) => saveStep(e, step.id)}
                                  />
                                </Grid>
                              </> : ''
                          }
                          {
                            (step['title'] !== undefined) ?
                              <>
                                <Grid item xs={12} sm={12} className="mb-1">
                                  <Typography className={classes.fontWeight500}>Title</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} className="mb-4">
                                  <FormControl fullWidth>
                                    <Input
                                      id={`${step.id}title${index}`}
                                      name="title"
                                      type={"text"}
                                      value={(step.title) ? step.title : ''}
                                      onInput={e => setValueStep(e.target.value, step.id, 'title')}
                                      onBlur={(e) => saveStep(e, step.id)}
                                    />
                                  </FormControl>
                                </Grid>
                              </> : ''
                          }
                          {
                            (step['description'] !== undefined) ?
                              <>
                                <Grid item xs={12} sm={12} className="mb-1">
                                  <Typography className={classes.fontWeight500}>Description</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} className="mb-4">
                                  <FormControl fullWidth>
                                    <SimpleMDE
                                      id={`${step.id}description${index}`}
                                      value={(step.description) ? step.description : ''}
                                      onChange={value => setValueStep(value, step.id, 'description')}
                                      events={{
                                        'blur': (event) => textEditor('description', step.id)
                                      }}
                                      className={classes.textAreaBorder}
                                    />
                                    {/* <Input
                                      id={`${step.id}description${index}`}
                                      name="description"
                                      value={(step.description) ? step.description : ''}
                                      onInput={e => setValueStep(e.target.value, step.id, 'description')}
                                      className={classes.textAreaBorder}
                                      onBlur={(e) => saveStep(e, step.id)}
                                      multiline /> */}

                                  </FormControl>
                                </Grid>
                              </> : ''
                          }
                          {
                            ((step.name.toLowerCase() === 'confirm' || step.name.toLowerCase() === 'goodbye') &&
                              step['terms_and_condition'] !== undefined) ?
                              <>
                                <Grid item xs={12} sm={12} className="mb-1">
                                  <Typography className={classes.fontWeight500}>Term & Conditions</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} className="mb-4">
                                  <FormControl fullWidth>
                                    <Input
                                      id={`${step.id}terms_and_condition${index}`}
                                      value={(step.terms_and_condition) ? step.terms_and_condition : ''}
                                      name="terms_and_condition"
                                      onBlur={(e) => saveStep(e, step.id)}
                                      onInput={e => setValueStep(e.target.value, step.id, 'terms_and_condition')}
                                      className={classes.textAreaBorder}
                                      multiline
                                    />
                                  </FormControl>
                                </Grid>
                              </> : ''
                          }
                          {
                            (step['dismiss_text'] !== undefined) ?
                              <>
                                <Grid item xs={12} sm={12} className="mb-1">
                                  <Typography className={classes.fontWeight500}>Dismiss Button</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} className="mb-4">
                                  <FormControl fullWidth>
                                    <Input
                                      id={`${step.id}dismiss_text${index}`}
                                      name="dismiss_text"
                                      value={(step.dismiss_text) ? step.dismiss_text : ''}
                                      type={"text"}
                                      onInput={e => setValueStep(e.target.value, step.id, 'dismiss_text')}
                                      onBlur={(e) => saveStep(e, step.id)}
                                    />
                                  </FormControl>
                                </Grid>
                              </> : ''
                          }
                          {
                            (step.form_type !== undefined && step.form_type !== 'reason' &&
                              step.step_reasons === undefined && step['next_text'] !== undefined) ?
                              <>
                                <Grid item xs={12} sm={12} className="mb-1">
                                  <Typography className={classes.fontWeight500}>Proceed Button</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} className="mb-4">
                                  <FormControl fullWidth>
                                    <Input
                                      id={`${step.id}next_text${index}`}
                                      name="next_text"
                                      value={(step.next_text) ? step.next_text : ''}
                                      type={"text"}
                                      onInput={e => setValueStep(e.target.value, step.id, 'next_text')}
                                      onBlur={(e) => saveStep(e, step.id)}
                                    />
                                  </FormControl>
                                </Grid>
                              </> : ''
                          }
                          {
                            (step.form_type !== undefined && step.form_type === 'reason' &&
                              step.step_reasons !== undefined) ?
                              <>
                                <Box className={classes.scrollView}>
                                  {step.step_reasons && step.step_reasons.map((x, i) => (
                                    <Grid container className="mt-2" alignItems="center" key={i}>
                                      <Grid item xs={2}>
                                        <Checkbox
                                          id={`${step.id}reason${i}`}
                                          name={`reason${step.id}`}
                                          color="primary"
                                          checked={(x.enabled) ? true : false}
                                          onChange={e =>
                                            selectingReason(i, e, step.id)
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={2}>
                                        <span className={`${classes.nodeDescIcon} ${classes.paddIcon}`}>
                                          {selectIcon(x)}
                                        </span>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Typography>{x.name}</Typography>
                                      </Grid>
                                      <Grid item xs={4}>
                                        {
                                          (x.offer !== void 0 && x.enabled) && <FormControl variant="standard" >
                                            <Select
                                              value={x.offer.offer_type}
                                              label="Offer Type"
                                              id={`${x.offer.id}offer_type`}
                                              onChange={(e) => setOfferStepOuter(e.target.value, 'offer_type', x.id, step.id)}
                                              style={{ fontSize: '14px' }}
                                            > {
                                                offerTypes.map((j, i) => <MenuItem value={j.value} key={i}>{j.name}</MenuItem>)
                                              }
                                            </Select>
                                          </FormControl>
                                        }
                                        {
                                          (x.offer === void 0 && x.enabled) && <FormControl variant="standard" >
                                            <Select
                                              value={'none'}
                                              label="Offer Type"
                                              id={`${i}offer_type`}
                                              onChange={(e) => setOfferStepOuter(e.target.value, 'offer_type', x.id, step.id)}
                                              style={{ fontSize: '14px' }}
                                            > {
                                                offerTypes.map((j, i) => <MenuItem value={j.value} key={i}>{j.name}</MenuItem>)
                                              }
                                            </Select>
                                          </FormControl>
                                        }

                                      </Grid>
                                    </Grid>
                                  ))}
                                </Box>
                              </> : ''
                          }
                        </Grid>)
                    }
                    {
                      lastOpen === '' && offer !== null && (
                        <Grid container alignItems="center" key={offer.id} >
                          <Grid item xs={12} sm={12} className="mb-4">
                            <FormControl variant="standard" className={classes.formControl} >
                              <InputLabel id={`${offer.offer.id}offer_type`} className={`${classes.fontWeight500} ${classes.offerTitle}`}>Offer Type</InputLabel>
                              <Select
                                value={offer.offer.offer_type}
                                label="Offer Type"
                                id={`${offer.offer.id}offer_type`}
                                onChange={(e) => setOfferStepChange(e.target.value, 'offer_type')}
                              >
                                {
                                  offerTypes.map((j, i) => <MenuItem value={j.value} key={i}>{j.name}</MenuItem>)
                                }
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-1">
                            <Typography className={classes.fontWeight500}>Title</Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-4">
                            <FormControl fullWidth>
                              <Input
                                id={`${offer.offer.id}title`}
                                name="title"
                                value={(offer.offer.title) ? offer.offer.title : ''}
                                type={"text"}
                                onInput={e => setOfferStep(e.target.value, 'title')}
                                onBlur={(e) => saveOffer(e.target.value, 'title', offer.offer.id)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-1">
                            <Typography className={classes.fontWeight500}>Description</Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-4">
                            <FormControl fullWidth>
                              <Input
                                id={`${offer.offer.id}description`}
                                name="description"
                                value={(offer.offer.description) ? offer.offer.description : ''}
                                type={"text"}
                                onInput={e => setOfferStep(e.target.value, 'description')}
                                onBlur={(e) => saveOffer(e.target.value, 'description', offer.offer.id)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-1">
                            <Typography className={classes.fontWeight500}>Accept Offer Button</Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-4">
                            <FormControl fullWidth>
                              <Input
                                id={`${offer.offer.id}next_text`}
                                name="next_text"
                                value={(offer.offer.next_text) ? offer.offer.next_text : ''}
                                type={"text"}
                                onInput={e => setOfferStep(e.target.value, 'next_text')}
                                onBlur={(e) => saveOffer(e.target.value, 'next_text', offer.offer.id)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-1">
                            <Typography className={classes.fontWeight500}>Decline Offer Button</Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-4">
                            <FormControl fullWidth>
                              <Input
                                id={`${offer.offer.id}decline_text`}
                                name="decline_text"
                                value={(offer.offer.decline_text) ? offer.offer.decline_text : ''}
                                type={"text"}
                                onInput={e => setOfferStep(e.target.value, 'decline_text')}
                                onBlur={(e) => saveOffer(e.target.value, 'decline_text', offer.offer.id)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} className="mb-3 mt-2" >
                            <hr />
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-1">
                            <Typography className={classes.fontWeight500}>Offer Accepted Title</Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-4">
                            <FormControl fullWidth>
                              <Input
                                id={`${offer.offer.id}accepted_title`}
                                name="accepted_title"
                                value={(offer.offer.accepted_title) ? offer.offer.accepted_title : ''}
                                type={"text"}
                                onInput={e => setOfferStep(e.target.value, 'accepted_title')}
                                onBlur={(e) => saveOffer(e.target.value, 'accepted_title', offer.offer.id)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-1">
                            <Typography className={classes.fontWeight500}>Offer Accepted Description</Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-4">
                            <FormControl fullWidth>
                              <Input
                                id={`${offer.offer.id}accepted_description`}
                                name="accepted_description"
                                value={(offer.offer.accepted_description) ? offer.offer.accepted_description : ''}
                                type={"text"}
                                onInput={e => setOfferStep(e.target.value, 'accepted_description')}
                                onBlur={(e) => saveOffer(e.target.value, 'accepted_description', offer.offer.id)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-1">
                            <Typography className={classes.fontWeight500}>Offer Accepted Dismiss Button</Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-4">
                            <FormControl fullWidth>
                              <Input
                                id={`${offer.offer.id}accepted_dismiss_text`}
                                name="accepted_dismiss_text"
                                value={(offer.offer.accepted_dismiss_text) ? offer.offer.accepted_dismiss_text : ''}
                                type={"text"}
                                onInput={e => setOfferStep(e.target.value, 'accepted_dismiss_text')}
                                onBlur={(e) => saveOffer(e.target.value, 'accepted_dismiss_text', offer.offer.id)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-1">
                            <Typography className={classes.fontWeight500}>Offer Accepted Dismiss Action</Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} className="mb-4">
                            <FormControl variant="standard" >
                              <Select
                                value={offer.offer.accepted_dismiss_action}
                                label="Offer Accepted Dismiss Action"
                                onChange={(e) => setOfferStep(e.target.value, 'accepted_dismiss_action')}
                                onBlur={(e) => saveOffer(e.target.value, 'accepted_dismiss_action', offer.offer.id)}
                              >
                                <MenuItem value={'reload'}>Reload Page</MenuItem>
                                <MenuItem value={'close'}>Close Modal</MenuItem>
                              </Select>
                            </FormControl>

                          </Grid>
                        </Grid>
                      )
                    }
                  </Box> : ''}
            </TabPanel>
            <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
              <Grid container className={classes.tabPanelSetting} >
                <Grid item xs={12} sm={4} >
                  <Typography>Name</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormControl fullWidth>
                    <Input
                      id="flowName"
                      value={flow.flowName}
                      type={"text"}
                      onChange={e => setValue(e, flow, setFlow)}
                      onBlur={saveFlow}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} className="mt-3">
                  <Typography>Enabled</Typography>
                </Grid>
                <Grid item xs={12} sm={8} className="mt-3">
                  <Switch
                    id="enabled"
                    name="enabled"
                    checked={(flow.enabled) ? true : false}
                    onChange={e => setValue(e, flow, setFlow)}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    onBlur={saveFlow}
                  />
                </Grid>
              </Grid>
              <List component="nav" onDragOver={dragOver} className={`${classes.sortListWrapper} sortListWrapper`}>
                {rightSideBar.map((x, i) =>
                  <ListItem className={`${x.name.toLowerCase()} ${classes.sortList} sortList`}
                    key={i} onDrop={(e) => dragDrop(e, i)} onDragStart={() => dragStart(i)} >
                    <ListItemText className={classes.sortListText} primary={x.title} draggable="true" /></ListItem>
                )}
              </List>
              <br />
              <Grid container className={classes.tabPanelSettings} >
                <Grid item xs={12} sm={4} >
                  <Typography>Custom css</Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.tabPanelSettings} >
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <ReactSandbox
                      classes={{
                        header: "hide"
                      }}
                      displayMode="tab" //Make the result show beneath code
                      selectedTab="stylesheetTab"
                      executeOnCodeChange
                      style={{ paddingBottom: "70px" }}
                      executeOnCodeChangeDebounce={1000}
                      scriptEditor={{
                        defaultValue: 'ReactDOM.render(\n  <label>Hello, world!</label>,\n  document.getElementById(\'root\')\n);',
                        mode: 'jsx'
                      }}
                      templateEditor={{
                        defaultValue: '<div id="root"></div>',
                        mode: 'html',
                        readOnly: false,
                        wrapLines: false
                      }}
                      stylesheetEditor={{
                        defaultValue: `${code}`,
                        value: '',
                        mode: "css",
                        readOnly: false,
                        wrapLines: true
                      }}
                      dependencies={[
                        "https://unpkg.com/@material-ui/core@3.0.0/umd/material-ui.development.js"
                      ]}
                      theme="solarized_light"
                      onCodeChange={(value, event) => handleChange(value, event)}
                    />
                  </FormControl>
                </Grid>
                <Button
                  variant={"contained"}
                  color={"success"}
                  onClick={customCss}
                  className={classes.buttonSettings}
                >
                  Save
                </Button>
              </Grid>

            </TabPanel>
            <TabPanel value={tabValue} index={2} className={classes.tabPanel}>
              <Box textAlign="center">Coming Soon</Box>
            </TabPanel>
          </Widget>
        </Grid>
      </Grid> </>
  );
};

const mapStateToProps = state => ({
  flowList: state.flows.flowList,
  flows: state.flows,
  breadCrumb: state.breadCrumb.name,
  selectedFlow: state.flows.selectedFlow
});

export default connect(mapStateToProps, null)(FlowStep);
