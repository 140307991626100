import {
    SET_BREADCRUMB_NAME
} from "../_constants";

const initialState = {
    breadcurmbName: "",
};
export default (state, action) => {
    switch (action.type) {
        case SET_BREADCRUMB_NAME:
            initialState.breadcurmbName = action.name;
            return {
                name: initialState.breadcurmbName
            }
        default:
            return { name: initialState.breadcurmbName };
    }
}